import { Container } from "react-bootstrap"
import Header from "../../layout/header/Header"
import AuthContext from "../../../context/authentication/auth-context"
import CartContext from "../../../context/cart/cart-context"
import { useContext, useEffect } from "react"
import AlertWindow from "../../ui/alerts/Alert"
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner"
import ProductItem from "./ProductItem"
import NFCDeskTab from "../../../assets/images/desktab.jpeg"
import NFCSticker from "../../../assets/images/tag.png"
import { getFirestore, updateDoc, doc } from "firebase/firestore/lite";
import PastOrders from "./PastOrders/PastOrders"

const Products = (props) => {
    const authCtx = useContext(AuthContext)
    const cartCtx = useContext(CartContext)
    
    useEffect(() => {
        if (authCtx.isLoggedIn) {
            const fbDb = getFirestore(props.fbApp)
            const docRef = doc(fbDb, "users", authCtx.userId);
            updateDoc(docRef, {
                cart: cartCtx.items,
            })}
    },[cartCtx, authCtx.userId])

    const nfcStickerSubTitle = 'Excellent for sticking on any surface, such as files, cards, folders, phones, etc.\nThis is possible our most basic product, but it serves many purposes. These decals are NOT water resistant'
    const nfcDeskTabSubtitle = 'Our desk tab is great for all businesses large and small. They can be at any reception and will serve to promote all our social media, google reviews, videos and espectially our contact, among others'

    return <>
        <Header loadedProfile={props.loadedProfile}></Header>
        <br/>
        <Container>
            {props.loadedProfile && <h2 className="text-center">Products</h2>}
            {!props.loadedProfile && <><h2 className="text-center">Loading Products</h2><br/><LoadingSpinner></LoadingSpinner></>}
            {props.loadedProfile && authCtx.isVerified && authCtx.status !== "active" &&
                <AlertWindow variant="danger" message="You are currently not on an active membership"></AlertWindow>}
            
                {props.loadedProfile && authCtx.isVerified && authCtx.status === "active" && <>
                    <ProductItem id={0} name="NFC Sticker" price="45" imgUrl={NFCSticker} subtitle={nfcStickerSubTitle}></ProductItem>
                    <ProductItem id={1} name="NFC Desk Tab" price="59.99" imgUrl={NFCDeskTab} subtitle={nfcDeskTabSubtitle}></ProductItem>
                </>}
                {props.loadedProfile && <><hr/><PastOrders loadedProfile={props.loadedProfile} fbApp={props.fbApp}></PastOrders></>}
        </Container>
    </>
}

export default Products