import ModalScreen from "../ui/modal/ModalScreen";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState, useRef, useContext } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import AuthContext from "../../context/authentication/auth-context";
import AlertWindow from "../ui/alerts/Alert";
import { useNavigate } from "react-router-dom";
import Header from "../layout/header/Header";

const SignInForm = (props) => {
  const [show, setShow] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const enteredEmail = useRef();
  const enteredPassword = useRef();

  const fbApp = props.fbApp;
  const fbAuth = getAuth(fbApp);
  const authCtx = useContext(AuthContext);

  const onSigningin = (e) => {
    e.preventDefault();
    if (
      enteredEmail.current.value === "" ||
      enteredPassword.current.value === ""
    ) {
    } else {
      signInWithEmailAndPassword(
        fbAuth,
        enteredEmail.current.value,
        enteredPassword.current.value
      )
        .then(() => {
          setErrorMessage("success");
          setTimeout(function () {
            onClosing();
          }, 2000);
        })
        .catch((error) => {
          authCtx.logout();
          setErrorMessage(error.message.split(":")[1]);
        });
    }
  };
  const onClosing = () => {
    setShow(false);
    navigate('/');
  };

  return (<>
    <Header loadedProfile={props.loadedProfile}></Header>
    <ModalScreen show={show} title="Sign In">
      <Form onSubmit={onSigningin}>
        <Form.Group className="mb-0" controlId="formPlaintextEmail">
          <Form.Label className="mb-0">Email</Form.Label>
          <Container>
            <Form.Control type="email" ref={enteredEmail} />
          </Container>
        </Form.Group>
        <Form.Group className="mb-0" controlId="formPlaintextPassword">
          <Form.Label className="mb-0">Password</Form.Label>
          <Container>
            <Form.Control type="password" ref={enteredPassword} />
          </Container>
        </Form.Group>
        <a href="/forgotpassword" style={{textDecoration:"none"}}>Forgot Password?</a>
        <br />
        <br />
        <Button className="m-1" variant="secondary" onClick={onClosing}>
          Close
        </Button>
        <Button className="m-1" type="submit" variant="primary">
          Sign In
        </Button>
      </Form>
      {errorMessage !== "" && errorMessage !== "success" && (
        <>
          <br></br>
          <AlertWindow message={errorMessage} variant="danger"></AlertWindow>
        </>
      )}
      {errorMessage === "success" && (
        <>
          <br></br>
          <AlertWindow
            message={"Welcome Back!"}
            variant="success"
          ></AlertWindow>
        </>
      )}
    </ModalScreen>
    </>
  );
};

export default SignInForm;
