import AlertWindow from "../ui/alerts/Alert"
import { Button } from "react-bootstrap"
import { getAuth, sendEmailVerification } from "firebase/auth"
import { useState } from "react"

const VerifyEmailAddress = (props) => {
    const [emailSent, setemailSent] = useState(false)

    const fbAuth = getAuth(props.fbApp);

    const resendVerificationEmail = () => {
        const user = fbAuth.currentUser
        console.log(user)
        sendEmailVerification(user).then(res =>{
            console.log('Verification Email Sent')
            setemailSent(true)
        })
    }
    return <><AlertWindow variant="danger" message="You are logged in but your account has not yet been verified, please check your email inbox to proceed or click on the below button to re-send the email">
    </AlertWindow>
    {!emailSent ? <Button onClick={resendVerificationEmail}>Resend Verification Email</Button> : <AlertWindow variant="success" message="Email has been sent"> </AlertWindow>}
    </>
}

export default VerifyEmailAddress