import { Image } from 'react-bootstrap';
import {Row} from 'react-bootstrap';
// import Carousel from 'react-bootstrap/Carousel';
// import Image1 from '../../../assets/images/1.jpg'
// import Image2 from '../../../assets/images/2.jpg'
// import Image3 from '../../../assets/images/3.jpg'
// import Image4 from '../../../assets/images/4.jpg'
// import Image5 from '../../../assets/images/5.jpg'
// import Image6 from '../../../assets/images/6.jpg'
// import Image7 from '../../../assets/images/7.jpg'
// import Image8 from '../../../assets/images/8.jpg'
// import Image9 from '../../../assets/images/9.jpg'
// import Image10 from '../../../assets/images/10.jpg'
// import Image11 from '../../../assets/images/11.jpg'
// import Image12 from '../../../assets/images/12.jpg'
import howitworks1 from '../../../assets/images/howitworks1.jpeg'
import howitworks2 from '../../../assets/images/howitworks2.jpeg'
import './IntroCarousel.css'

function IntroCarousel() {
  return (
    // <Carousel>
    //   <Carousel.Item interval={3000}>
    //     <Image style={{width:'75%'}} src={howitworks1}></Image>
    //     <Carousel.Caption>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item interval={3000}>
    //     <Image style={{width:'75%'}} src={howitworks2}></Image>
    //     <Carousel.Caption>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    // </Carousel>
    <>
    <Row><Image src={howitworks1}></Image></Row>
    <Row><Image src={howitworks2}></Image></Row>
    </>
  );
}

export default IntroCarousel;