import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header.css";
import okiNewLogo from "../../../assets/images/okinewlogo.jpg"
import okiRightSide from "../../../assets/images/artworkright.png"
import artworkleft from "../../../assets/images/artworkleft.jpg"
import { useContext} from "react";
import CartContext from "../../../context/cart/cart-context";
import AuthContext from "../../../context/authentication/auth-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCartShopping} from '@fortawesome/free-solid-svg-icons'


const Header = (props) => {
  const authCtx = useContext(AuthContext)
  const cartCtx = useContext(CartContext)

  return (
    <Navbar expand="lg" variant="light" className="d-flex justify-content-between navBarColor p-0">
      <img src={artworkleft} alt="OkiTech" style={{height:'75px'}}></img>
        <Navbar.Brand href="/"><img className="logoblack " src={okiNewLogo} alt="OkiTech" style={{width:'100px'}}></img></Navbar.Brand>
        <div className="text-end">
        {(props.loadedProfile && cartCtx.totalQuantity > 0) && <span className="hamburgerBadge text-center">{cartCtx.totalQuantity}</span>}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {props.loadedProfile && authCtx.isLoggedIn && <Nav className="ms-auto">
                <Nav.Link href="/membership" className="li text-end">Membership</Nav.Link>
                <Nav.Link href="/cardsettings" className="li text-end">Card Settings</Nav.Link>
                <Nav.Link href="/products" className="li text-end">Products</Nav.Link>
                <Nav.Link href="/signout" className="li text-end">Sign out</Nav.Link>
                <Nav.Link href="/cart" className="li text-end"><FontAwesomeIcon icon={faCartShopping} />
                {(props.loadedProfile && cartCtx.totalQuantity > 0) && <span className="badge">{cartCtx.totalQuantity}</span>}
                </Nav.Link>
                
              </Nav>}
              {props.loadedProfile && !authCtx.isLoggedIn &&
                <>   
                  <Nav.Link href="/register" className="text-end">Register</Nav.Link>
                  <Nav.Link href="/login" className="text-end">Sign in</Nav.Link>
                </>  
              }
            </Nav>
          </Navbar.Collapse>
        </div>
        <img src={okiRightSide} alt="OkiTech" style={{height:'75px'}}></img>
    </Navbar>
  );
};

export default Header;
