import CartContext from "./cart-context";
import { useReducer } from "react";

const defaultCartState = {
    items: [],
    totalAmount: 0,
    totalQuantity: 0,
}

const cartReducer = (state,action) => {
    if (action.type === 'ADD'){
        const updatedTotalAmount = Math.round((state.totalAmount + (action.item.price * action.item.amount))*100)/100;
        const existingCartItemIndex = state.items.findIndex(item => item.id === action.item.id);
        const existingCartItem = state.items[existingCartItemIndex];
        let updatedItems;
        if (existingCartItem) {
            const updatedItem = {...existingCartItem, amount: existingCartItem.amount + action.item.amount}
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        } else {
            updatedItems = state.items.concat(action.item);
        }

        let updatedTotalQuantity = 0
        updatedItems.forEach(item => {
            updatedTotalQuantity = parseInt(updatedTotalQuantity) + parseInt(item.amount)
        })       
        return {
            items: updatedItems,
            totalAmount: updatedTotalAmount,
            totalQuantity: updatedTotalQuantity
        }
    }
    if (action.type === 'REMOVE'){
        
        const existingCartItemIndex = state.items.findIndex(
            (item) => item.id === action.id
        );
        const existingCartItem = state.items[existingCartItemIndex];
        
        const updatedTotalAmount = Math.round((state.totalAmount - existingCartItem.price)*100)/100;
        let updatedItems;

        if(existingCartItem.amount === 1) {
            updatedItems = state.items.filter(item => item.id !== action.id);
        } else {
            const updatedItem = {...existingCartItem, amount: existingCartItem.amount - 1}
            updatedItems = [...state.items]
            updatedItems[existingCartItemIndex] = updatedItem
        }

        let updatedTotalQuantity = 0
        updatedItems.forEach(item => {
            updatedTotalQuantity = updatedTotalQuantity + item.amount
        })

        return {
            items: updatedItems,
            totalAmount: updatedTotalAmount,
            totalQuantity: updatedTotalQuantity,
        }
    }

    if (action.type === 'CLEAR') {
        return defaultCartState
    }


    return defaultCartState
}

const CartProvider = props => {
    const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState);
    const addItemToCartHandler = item => {
        dispatchCartAction({type: 'ADD', item: item});
    };

    const removeItemFromCartHandler = id => {
        dispatchCartAction({type: 'REMOVE', id: id});
    };

    const clearCartHandler = () => {
        dispatchCartAction({type: 'CLEAR'})
    }

    const setCartItems = (items) => {
        dispatchCartAction({type: 'CLEAR'})
        items.forEach(item => {
            dispatchCartAction({type: 'ADD', item: item});
        })
    }

    const cartContext = {
        items: cartState.items,
        totalAmount: cartState.totalAmount,
        totalQuantity: cartState.totalQuantity,
        addItem: addItemToCartHandler,
        removeItem: removeItemFromCartHandler,
        clearCart: clearCartHandler,
        setCart: setCartItems,
    }
    return <CartContext.Provider value={cartContext}>
        {props.children}
    </CartContext.Provider>
};

export default CartProvider