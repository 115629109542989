import { Container } from "react-bootstrap"
import AuthContext from "../../../context/authentication/auth-context";
import { useContext, useState, useEffect } from "react"
import AlertWindow from "../../ui/alerts/Alert";
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner";
import { Button} from "react-bootstrap"
import PackageCard from "../../widgets/packageCard";
import VerifyEmailAddress from "../../widgets/VerifyEmailAlert";
import Header from "../../layout/header/Header";
import InputComponent from "../Profile/InputComponent";
import { getDoc, doc, collection, getDocs, getFirestore, updateDoc, setDoc} from "firebase/firestore";
import ManagedUsers from "./ManagedUsers";
import PasswordChangeForm from "./PasswordChangeForm";
import Footer from "../../layout/footer/Footer";

const Membership = (props) => {
    const [membershipData, setMembershipData] = useState({})
    const [enteredSubId, setenteredSubId] = useState("")
    const [activatingMembership, setactivatingmembership] = useState(false)
    const [activationError, setactivationError] = useState("")
    const [searchingSubscription, setsearchingSubscription] = useState(false)
    const authCtx = useContext(AuthContext);
    
    useEffect(()=>{
        if (authCtx.subId !== "" && authCtx.status === "active") {
          setsearchingSubscription(true)
            const fbDb = getFirestore(props.fbApp)
            const subRef = doc(fbDb,"subscriptions",authCtx.subId)
            getDoc(subRef).then(res => {
              const endDateFormat = new Date(res.data().endDate * 1000)
              const date = endDateFormat.getDate()
              const dayNumber = endDateFormat.getDay()
              const monthNumber =  endDateFormat.getMonth()
              const year = endDateFormat.getYear() - 100 + 2000

              let day = "";
              switch (dayNumber) {
                case 0:
                  day = "Sun";
                  break;
                case 1:
                  day = "Mon";
                  break;
                case 2:
                  day = "Tue";
                  break;
                case 3:
                  day = "Wed";
                  break;
                case 4:
                  day = "Thu";
                  break;
                case 5:
                  day = "Fri";
                  break;
                case 6:
                  day = "Sat";
                  break;
                default:
                  day = "Sun";
              }
            
              let ordinal;
            
              if (date > 10 && date < 20) {
                ordinal = "th";
              } else {
                switch (date % 10) {
                  case 1:
                    ordinal = "st";
                    break;
                  case 2:
                    ordinal = "nd";
                    break;
                  case 3:
                    ordinal = "rd";
                    break;
                  default:
                    ordinal = "th";
                }
              }
            
              let month;
              switch (monthNumber) {
                case 0:
                  month = "Jan";
                  break;
                case 1:
                  month = "Feb";
                  break;
                case 2:
                  month = "Mar";
                  break;
                case 3:
                  month = "Apr";
                  break;
                case 4:
                  month = "May";
                  break;
                case 5:
                  month = "June";
                  break;
                case 6:
                  month = "July";
                  break;
                case 7:
                  month = "Aug";
                  break;
                case 8:
                  month = "Sep";
                  break;
                case 9:
                  month = "Oct";
                  break;
                case 10:
                  month = "Nov";
                  break;
                case 11:
                  month = "Dec";
                  break;
                default:
                  month = "";
              }
              let price
              if (res.data().memberType === "Month" && res.data().maxSeats === 1) {
                price = "2.99 $Aud"
              } else if (res.data().memberType === "Year" && res.data().maxSeats === 1) {
                price = "12.99 $Aud"
              } else if (res.data().memberType === "Month" && res.data().maxSeats === 10) {
                price = "29.9 $Aud"
              } else if (res.data().memberType === "Year" && res.data().maxSeats === 10) {
                price = "116 $Aud"
              } else if (res.data().memberType === "Month" && res.data().maxSeats === 20) {
                price = "59.8 $Aud"
              } else if (res.data().memberType === "Year" && res.data().maxSeats === 20) {
                price = "233 $Aud"
              } else if (res.data().memberType === "Month" && res.data().maxSeats === 50) {
                price = "149.5 $Aud"
              } else if (res.data().memberType === "Year" && res.data().maxSeats === 50) {
                price = "584 $Aud"
              } else if (res.data().memberType === "Month" && res.data().maxSeats === 100) {
                price = "299 $Aud"
              } else if (res.data().memberType === "Year" && res.data().maxSeats === 100) {
                price = "1,169 $Aud"
              } else {
                price = "Trial Account"
              }

              let renewalDateTitle
              const portalButton = <Button className="bg-success border border-success" id="portal-button" type="submit">Manage Membership</Button>

              if (authCtx.cancelAtPeriodEnd) {
                  renewalDateTitle = "This membership ends on:"
              } else {
                  renewalDateTitle = "Next Renewal Date:"
              }

              setMembershipData({
                  renewalDate: `${day}, ${month} ${date}${ordinal}, ${year}`,
                  renewalDateTitle: renewalDateTitle,
                  term: authCtx.memberType + "ly Membership",
                  price: price,
                  portalButton: portalButton,
              })
              setsearchingSubscription(false)
            }).catch(()=>{
              setsearchingSubscription(false)
            })            
        } else {
            setMembershipData({})
        }
    },[authCtx.subId,authCtx.status, authCtx.endDate, authCtx.memberType,authCtx.cancelAtPeriodEnd, authCtx.cusId])

    const onTyping = (subId) => {
      setenteredSubId(subId)
    }
    const onActivatingMembership = () => {
      setactivatingmembership(true)
      const fbDb = getFirestore(props.fbApp)
      getDocs(collection(fbDb,"subscriptions")).then(querySnapshot => {
        let count = 0
        querySnapshot.forEach(document => {
          count += 1
          if (document.id === enteredSubId.value) {
            const userRef = doc(fbDb,"users", authCtx.userId)
            updateDoc(userRef,{
              memberType: document.data().memberType,
              cusId: document.data().cusId,
              startDate: document.data().startDate,
              endDate: document.data().endDate,
              status: document.data().status,
              isMembershipOwner: false,
              membershipOwner: document.data().membershipOwner,
              subId: enteredSubId.value,
            }).then(res => {
              const timestamp = new Date().getTime()
              const mailRef = doc(fbDb,"mail", String(timestamp));
              setDoc(mailRef,{
                to: [authCtx.email],
                message: {
                  subject: 'Successfully enrolled in enterprise subscription!',
                  text: `Subscription ID: ${enteredSubId.value}\nMembership Owner: ${document.data().membershipOwner}\nStatus: ${document.data().status}\nMax Number of Seats: ${document.data().maxSeats}\nRemaining Seats: ${document.data().seats}`
                }
              }).then(res => {
                authCtx.updateMembershipOwner(false, document.data().membershipOwner)
                authCtx.updateSubscription(document.data().startDate, document.data().endDate, document.data().status, enteredSubId.value, document.data().cusId, document.data().memberType, false)
                setactivatingmembership(false)
                setactivationError("")
              }).catch(err => {
                setactivationError(err.message)
                setactivatingmembership(false)
              })
            }).catch(err => {
              setactivationError(err.message)
              setactivatingmembership(false)
            })
            return
          } else if (count === querySnapshot.size) {
            setactivatingmembership(false)
            setactivationError("The entered subscription ID is incorrect, please check the subscription ID with your manager")
          }
        })
        setactivatingmembership(false)
      })
    }
    return <>
    <Header loadedProfile={props.loadedProfile} ></Header>
    <Container>
        <br/>
        <div className="text-center">
            {(!props.loadedProfile || searchingSubscription) && <><h2 className="text-center">Loading Membership Data</h2><br/><LoadingSpinner></LoadingSpinner></>}
            {!authCtx.enabledByAdmin && <AlertWindow variant="danger" message="This account has been administratively disabled"></AlertWindow>}
            {authCtx.enabledByAdmin && props.loadedProfile && authCtx.isVerified && Object.keys(membershipData).length === 0 && !searchingSubscription &&<>
                <AlertWindow variant="danger" message="You are currently not on an active membership. Please sign up into one of our below packages"></AlertWindow>
                <hr/>
                <h4 className="text-center">If your company has an enterprise membership, paste the subscription ID below</h4>
                <InputComponent inputType="Subscription ID" type="text" onTyping={onTyping} value=""></InputComponent>
                <br/>
                {activatingMembership && <LoadingSpinner></LoadingSpinner>}
                {!activatingMembership && <Button onClick={onActivatingMembership} disabled={authCtx.status === "active"}>Activate Membership</Button>}
                <br/>
                <br/>
                {activationError !== "" && <AlertWindow variant="danger" message={activationError}></AlertWindow>}
                <hr/>
                <div>
                  <h2>Personal Packages</h2>
                </div>
                <div className="justify-content-center d-md-flex d-sm-block">
                    <PackageCard plan="Yearly Membership" price="12.99 AU$" term="Year" pricePresent={true}></PackageCard>
                    <PackageCard plan="Monthly Membership" price="2.99 AU$" term="Month" pricePresent={true}></PackageCard>
                </div>
                <hr/>
                <div>
                  <h2>Enterprise Packages</h2>
                </div>
                <div>
                  <div>
                    <br/>
                    <div>
                      <h2>10 users</h2>
                    </div>
                    <div className="d-md-flex justify-content-center d-sm-block">
                      <PackageCard plan="Yearly Membership" price="116 AU$" term="Year" users="10" pricePresent={true}></PackageCard>
                      <PackageCard plan="Monthly Membership" price="29.9 AU$" term="Month" users="10" pricePresent={true}></PackageCard>
                    </div>
                  </div>
                  <hr/>
                  <div>
                    <br/>
                    <div>
                      <h2>20 users</h2>
                    </div>
                    <div className="d-md-flex justify-content-center d-sm-block">
                      <PackageCard plan="Yearly Membership" price="233 AU$" term="Year" users="20" pricePresent={true}></PackageCard>
                      <PackageCard plan="Monthly Membership" price="59.8 AU$" term="Month" users="20" pricePresent={true}></PackageCard>
                    </div>
                  </div>
                  <div>
                    <br/>
                    <hr/>
                    <div>
                      <h2>50 users</h2>
                    </div>
                    <div className="d-md-flex justify-content-center d-sm-block">
                      <PackageCard plan="Yearly Membership" price="584 AU$" term="Year" users="50" pricePresent={true}></PackageCard>
                      <PackageCard plan="Monthly Membership" price="149.5 AU$" term="Month" users="50" pricePresent={true}></PackageCard>
                    </div>
                  </div>
                  <div>
                    <br/>
                    <hr/>
                    <div>
                      <h2>100 users</h2>
                    </div>
                    <div className="d-md-flex justify-content-center d-sm-block">
                      <PackageCard plan="Yearly Membership" price="1169 AU$" term="Year" users="100" pricePresent={true}></PackageCard>
                      <PackageCard plan="Monthly Membership" price="299 AU$" term="Month" users="100" pricePresent={true}></PackageCard>
                    </div>
                  </div>

                </div>
            </>}
            {!props.loadingProfile && !authCtx.isVerified && !searchingSubscription && <VerifyEmailAddress fbApp={props.fbApp}></VerifyEmailAddress> }
            {!props.loadingProfile && Object.keys(membershipData).length > 0 && !searchingSubscription && <>
                <h2 className="text-center">Membership</h2>
                <br/>
                <div className="align-items-center">
                    <div className="text-start align-items-center">
                        <ul className="list-unstyled">
                            {authCtx.isMembershipOwner && <><li><h3>{`${membershipData.term}`}</h3></li>
                            <li><h4>{`${membershipData.price}`}</h4></li></>}
                            {authCtx.isMembershipOwner && <><li><h4>{membershipData.renewalDateTitle}</h4> {`${membershipData.renewalDate}`}</li>
                            <br/>
                            <br/></>}
                            <li><span className="fw-bold">Subscription ID: </span> {authCtx.subId}</li>
                            <li><span className="fw-bold">User ID: </span> {authCtx.userId}</li>
                        </ul>
                        {authCtx.isMembershipOwner && <AlertWindow variant="primary" message="You are the membership owner. Please provide the above Subscription ID to your team"></AlertWindow>}
                    </div>
                    {authCtx.isMembershipOwner && <div>
                        <form action={process.env.REACT_APP_PORTAL_SESSION} method="POST">
                            <input type="hidden" name="cusId" value={authCtx.cusId} />
                            {membershipData.portalButton}
                        </form>
                    </div>}
                    {!authCtx.isMembershipOwner && <AlertWindow variant="primary" message={`The membership owner for this subscription is ${authCtx.membershipOwner}`}></AlertWindow>}
                    <br/>
                    {authCtx.isMembershipOwner && <ManagedUsers fbApp={props.fbApp}></ManagedUsers>}
                </div>
            </>}
        <hr/>
        <PasswordChangeForm fbApp={props.fbApp}></PasswordChangeForm>
        </div>
    </Container>
    {/* {props.loadedProfile && <Footer></Footer>} */}
    </>
}

export default Membership