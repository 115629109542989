import AuthContext from "../../../context/authentication/auth-context";
import { useContext, useEffect, useState } from "react";
import { collection, getFirestore, getDocs } from "firebase/firestore";
import { Table } from "react-bootstrap";
import ManagedUser from "./ManagedUser";
import AlertWindow from "../../ui/alerts/Alert";

const ManagedUsers = (props) => {
    const[users, setUsers] = useState([])
    const[userChangeMessage, setUserChangeMessage] = useState("")

    const authCtx = useContext(AuthContext)
    const fbDb = getFirestore(props.fbApp)

    useEffect(() =>{
        //Get Users Collection and filter all users with the same subscription ID as the local user (which at this point must be the subscription owner)
        let subscribedUsers = [];
        let index  = 0;
        getDocs(collection(fbDb, "users")).then(querySnapshot => {
            querySnapshot.forEach(doc => {
                if (doc.data().subId === authCtx.subId && authCtx.email !== doc.data().email) {
                    subscribedUsers.push({
                        id: index,
                        email: doc.data().email,
                        cardUrl: doc.data().cardUrl,
                        enabledByAdmin: doc.data().enabledByAdmin,
                        userId: doc.id
                    })
                    index += 1
                }   
            })
            setUsers(subscribedUsers);
        }).catch(err => {})
    },[userChangeMessage])

    const afterChangingUser = (email, deactOrAct) => {
        setUserChangeMessage(<p>{`User ${email} has been successfully `}<span className="fw-bold">{deactOrAct}</span></p>)
    }

    return <>
            {userChangeMessage !== "" && <AlertWindow variant="success" message={userChangeMessage}></AlertWindow>}
            {(users.length > 0) && 
            <>
            <div className="d-flex justify-content-around">
                <h2>Users already in this subscription</h2>
            </div>
            <br/>
            <Table striped bordered hover size="sm">
            <thead>
                <tr>
                <th>Email</th>
                <th>URL</th>
                <th>QR Code</th>
                <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => <ManagedUser key={user.id} afterChangingUser={afterChangingUser} enabledByAdmin={user.enabledByAdmin} email={user.email} cardUrl={user.cardUrl} userId={user.userId} fbApp={props.fbApp}></ManagedUser>)}
            </tbody>
        </Table></>}
        </>
}

export default ManagedUsers;