import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";

const PackageCard = (props) => {

  const navigate = useNavigate();

  const onPayHandler = (e) => {
    e.preventDefault()
    if(!props.pricePresent) {
      navigate(`/${props.redirectTo}`)
    } else {
      if (props.users) {
        navigate('/payment/' + props.term + "-" + props.users)
      } else {
        navigate('/payment/' + props.term)
      }
      
    }
    
  }

  return (
    <div className="d-flex justify-content-center text-center">
      <Card style={{ width: "18rem" }} className="mt-5 mx-5">
        <Card.Body>
          <Card.Title>{props.plan}</Card.Title>
          {props.pricePresent && <Card.Text>{props.price}/{props.term}</Card.Text>}
          <Button className="m-1 bg-success border border-success" onClick={onPayHandler}>
            Get          
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PackageCard;
