import AddButton from "../AddButton/AddButton"
import { useState, useEffect, useContext} from "react"
import SmLinkEntry from "./smLinkEntry"
import AuthContext from "../../../../context/authentication/auth-context"

const SmLinksForm = (props) => {
    const [personalLinkArray, setpersonalLinkArray] = useState([])
    const [workLinkArray, setworkLinkArray] = useState([])

    const authCtx = useContext(AuthContext)

    //Checking if context already have Social Media Links
    useEffect(() => {
        setpersonalLinkArray(authCtx.personalSmLinkArray)
        setworkLinkArray(authCtx.workSmLinkArray)
    },[authCtx.personalSmLinkArray, authCtx.workSmLinkArray])

    const onAddingLink = (addedLink) => {
        if (addedLink.subType === "Personal") {
            setpersonalLinkArray(prevState => {
                const newArray = [...prevState]
                let key
                if (newArray.length === 0) {
                    key = 0
                } else {
                    key = newArray[newArray.length - 1].key + 1
                }
                newArray.push({key: key, subType: "Personal", url: "", network: ""})
                return newArray
            })
        } else if (addedLink.subType === "Work") {
            setworkLinkArray(prevState => {
                const newArray = [...prevState]
                let key
                if (newArray.length === 0) {
                    key = 0
                } else {
                    key = newArray[newArray.length - 1].key + 1
                }
                newArray.push({key: key, subType: "Work", url: "", network: ""})
                return newArray
            })
        }
    }

    const onTyping = (e) => {
        if (e.subType === "Personal") {
            //Modify the correct link within personalLinkArray
            setpersonalLinkArray(prevState => {
                const newArray = [...prevState]
                const linkIndex = newArray.findIndex(link => link.key === e.key)
                newArray[linkIndex].url = e.url
                newArray[linkIndex].network = e.network
                newArray[linkIndex].name = e.name
                return newArray
            })
        } else if (e.subType === "Work") {
            //Modify the correct link within workLinkArray
            setworkLinkArray(prevState => {
                const newArray = [...prevState]
                const linkIndex = newArray.findIndex(link => link.key === e.key)
                newArray[linkIndex].url = e.url
                newArray[linkIndex].network = e.network
                newArray[linkIndex].name = e.name
                return newArray
            })
        }
    }

    //If a link is removed, we eliminate the link from the linkArray
    const onRemoving = (objectToRemove) =>{
        if (objectToRemove.subType === 'Work') {
            setworkLinkArray(prevState => {
                const newArray = [...prevState]
                const index = newArray.findIndex(link => link.key === objectToRemove.index)
                newArray.splice(index,1)
                return newArray
            })
        } else if (objectToRemove.subType === 'Personal') {
            setpersonalLinkArray(prevState => {
                const newArray = [...prevState]
                const index = newArray.findIndex(link => link.key === objectToRemove.index)
                newArray.splice(index,1)
                return newArray
            })
        }
    }

    //Notify calling component of every modification
    useEffect(() =>{
        props.onUpdatingLinks([personalLinkArray,workLinkArray])
    },[personalLinkArray, workLinkArray])

    return <div >
        <div className="d-flex justify-content-around">
            <h2>Social Media Links</h2>
        </div>
        <br/>
        <div className="d-flex justify-content-around">
            <AddButton type="Add" subType="Personal" widget="Link" onAdding={onAddingLink}></AddButton>
            <AddButton type="Add" subType="Work" widget="Link" onAdding={onAddingLink}></AddButton>
        </div>
        <br/>
        {(workLinkArray.length > 0)  && <>
            <div className="d-flex justify-content-around">
                <h4>Work Links</h4>
            </div>
            <div>
                {workLinkArray.map(link => (<SmLinkEntry key={link.key} index={link.key} subType="Work" network={link.network} url={link.url} name={link.name} onRemoving={onRemoving} onTyping={onTyping}></SmLinkEntry>))}
            </div>
        </>}
        {(personalLinkArray.length > 0)  && <>
            <div className="d-flex justify-content-around">
                <h4>Personal Links</h4>
            </div>
            <div>
                {personalLinkArray.map(link => (<SmLinkEntry key={link.key} index={link.key} subType="Personal" network={link.network} url={link.url} name={link.name} onRemoving={onRemoving} onTyping={onTyping}></SmLinkEntry>))}
            </div>
        </>}
    </div>
}

export default SmLinksForm