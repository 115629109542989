import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faQrcode} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner"
import { doc, getFirestore, updateDoc} from "firebase/firestore";

const ManagedUser = (props) => {

    const [modifyingUserSubs, setModifyingUserSubs] = useState(false)
    const [errorMessage, seterrorMessage] = useState('')

    const navigate = useNavigate();
    const fbDb = getFirestore(props.fbApp)
    const userRef = doc(fbDb,'users', props.userId)

    const onDisplayingQr  = () => {
        navigate(`/qr/${props.cardUrl}`)
    }

    const onDeactivating = (e) => {
        e.preventDefault()
        setModifyingUserSubs(true)
        updateDoc(userRef,{
            enabledByAdmin: false,
            cardActive: false,
            status: "Disabled by Admin"
        }).then(res => {
            setModifyingUserSubs(false)
            props.afterChangingUser(props.email, 'disabled')
        }).catch(err => {
            setModifyingUserSubs(false)
            seterrorMessage(err.message)
        })
    }
    const onActivating = (e) => {
        e.preventDefault()
        setModifyingUserSubs(true)
        updateDoc(userRef,{
            enabledByAdmin: true,
            status: "active",
        }).then(res => {
            setModifyingUserSubs(false)
            props.afterChangingUser(props.email, 'enabled')
        }).catch(err => {
            setModifyingUserSubs(false)
            seterrorMessage(err.message)
        })
    }

    return <>
        <tr>
            <td><small>{props.email}</small></td>
            <td><a target="_blank" href={`https://okitech.com.au/card/${props.cardUrl}`} className="small">Card</a></td>
            <td><FontAwesomeIcon icon={faQrcode} type="button" onClick={onDisplayingQr}></FontAwesomeIcon></td>
            {(props.enabledByAdmin && !modifyingUserSubs) && <td><a href='#' className="text-success fw-bold cursor-pointer" onClick={onDeactivating}>Enabled</a></td>}
            {(!props.enabledByAdmin && !modifyingUserSubs) && <td><a href='#' className="text-danger fw-bold cursor-pointer" onClick={onActivating}>Disabled</a></td>}
            {modifyingUserSubs && <LoadingSpinner></LoadingSpinner>}
        </tr>
    </>
}

export default ManagedUser