import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { doc, getDoc,getFirestore } from "firebase/firestore";
import Container from "react-bootstrap/esm/Container";
import Card from 'react-bootstrap/Card';
import { Image, Row } from "react-bootstrap";
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner";
import AlertWindow from "../../ui/alerts/Alert";
import {Button} from "react-bootstrap";
import SmLinkSection from "./Widgets/smLinkSection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import vCard from "vcards-js";
import artworkTopLeft from "../../../assets/images/ps-artwork-top-left.png"
import artworkBottomRight from "../../../assets/images/ps-artwork-bottom-right.jpg"

const PresentationCard = (props) => {
    const params = useParams();
    const [orderData, setOrderData] =useState("")
    const [loadingData, setloadingData] =useState(true)
    const [workLinks, setworkLinks] =useState([])
    const [personalLinks, setpersonalLinks] =useState([])
    const[bgColor, setbgColor] =useState("#FFFFFF")
    const[errorMessage, setErrorMessage] = useState("")
    //Get order doc
    useEffect(()=> { 
        const fbDb = getFirestore(props.fbApp);
        const docRef = doc(fbDb, "cards", params.orderNumber);
        const userRef = doc(fbDb, "users",params.orderNumber.split("-")[1])
        getDoc(docRef).then((doc) =>{
                //Check if user and card are both active
                getDoc(userRef).then(res => {
                    if(res.data().status === 'active' && res.data().cardActive) {
                        setOrderData(doc.data());
                        setloadingData(false)
                    } else if (!res.data().cardActive) {
                        setErrorMessage('This business card is currently inactive, please contact card owner')
                        setloadingData(false)
                    } else if (res.data().status !== 'active') {
                        setErrorMessage('This card is not under an active membership, please contact card owner')
                        setloadingData(false)
                    }
                }).catch(err => {
                    setErrorMessage(err.message)
                    setloadingData(false)
                })
        }).catch((err) => {
            setErrorMessage(err.message)
            setloadingData(false)
        })
    },[params.orderNumber, props.fbApp])

    useEffect(() => {
        if(orderData.personalSmLinkArray || orderData.workSmLinkArray) {
            
            setpersonalLinks(orderData.personalSmLinkArray)
            setworkLinks(orderData.workSmLinkArray)
        }

    },[orderData])

    const onSavingContact = () => {
        const vcf = vCard()

        vcf.firstName = orderData.mainFields["First Name"]
        vcf.lastName = orderData.mainFields["Last Name"]
        vcf.organization = orderData.mainFields["Company"]
        vcf.photo.attachFromUrl(orderData.pictureUrl)
        vcf.cellPhone = orderData.mainFields["Personal Phone Number"]
        vcf.email = orderData.mainFields["Company Email Address"]
        if (Object.keys(orderData.mainFields).includes("Company Phone Number")) {
            if (orderData.mainFields["Company Phone Number"] !== "") {
                vcf.workPhone = orderData.mainFields["Company Phone Number"]
            }
        }
        vcf.title = orderData.mainFields["Title"]

        const linkElement = document.createElement("a");
        const url = `data:text/x-vcard;charset=utf-8,${encodeURIComponent(vcf.getFormattedString())}`
        linkElement.setAttribute("href", url);
        // linkElement.setAttribute("Content-Type", `application/octet-stream`);
        linkElement.setAttribute("download", `${orderData.mainFields["First Name"]}_${orderData.mainFields["Last Name"]}.vcf`)
        linkElement.style.display = "none";
        document.body.appendChild(linkElement);
        linkElement.click();
        document.location.href = url;
        document.body.removeChild(linkElement);


    }

    return (<>
            {loadingData && <div className="py-5" style={{backgroundColor:bgColor, height:"2000px"}}><LoadingSpinner></LoadingSpinner></div>}
            {!loadingData && errorMessage !== "" && <div style={{backgroundColor:bgColor,height:"2000px"}}>
                    <Container>
                        <br/>
                        <AlertWindow variant="danger" message={errorMessage}>
                        </AlertWindow>
                    </Container>
            </div>}
            {(!loadingData && errorMessage === "" && orderData !== undefined) && 
            <div style={{backgroundColor:bgColor}}>
                <img src={artworkTopLeft} alt="OkiTech" style={{height:'75px'}}></img>
                <Container>
                    <Card className="text-black border-0" style={{backgroundColor:bgColor}}>
                        <Card.Body className="p-0">
                            <br/>
                            <div className="text-center">
                                <Row style={{maxHeight: "300px"}} className="d-flex justify-content-center">
                                    <div style={{width: '100px', height: '100px', overflow: 'hidden', borderRadius: '50%', paddingLeft: "0"}}>
                                        <Image rounded src={orderData.pictureUrl} style={{width: '100px', height: '100px', objectFit: 'cover'}}></Image>
                                    </div>
                                </Row>
                                <br/>
                                <Row className="justify-content-center">
                                    <h2 className="display-5">{orderData.mainFields["First Name"]} {orderData.mainFields["Last Name"]}</h2>
                                </Row>
                                <Row className="justify-content-center">
                                    <p className="lead">{orderData.mainFields["Title"]} at {orderData.mainFields["Company"]}</p>
                                </Row>
                                <hr />

                                <Row className="d-flex text-end">
                                    <ul>
                                        <li style={{listStyle: "none"}}>
                                            <div className="d-flex align-items-center" >
                                                <a href={`mailto:${orderData.mainFields["Company Email Address"]}`}><FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon></a>
                                                
                                                <div className="ms-2 text-start" >
                                                    <p className="lead" style={{marginTop:"10px",marginBottom:"0px",marginLeft:"10px",marginRight:"10px"}}>{orderData.mainFields["Company Email Address"]}</p>
                                                    <p style={{marginTop:"0px",marginBottom:"10px",marginLeft:"10px",marginRight:"10px"}}>E-mail</p>
                                                </div>
                                                <hr/>
                                            </div>
                                        </li>
                                        <li style={{listStyle: "none"}}>
                                            <div className="d-flex align-items-center">
                                                <a href={`tel:${orderData.mainFields["Personal Phone Number"]}`}><FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon></a>
                                                <div className="ms-2 text-start">
                                                    <p className="lead" style={{marginTop:"10px",marginBottom:"0px",marginLeft:"10px",marginRight:"10px"}}>{orderData.mainFields["Personal Phone Number"]}</p>
                                                    <p style={{marginTop:"0px",marginBottom:"10px",marginLeft:"10px",marginRight:"10px"}}>Personal Number</p>
                                                </div>
                                                <hr/>
                                            </div>
                                        </li>
                                        <li style={{listStyle: "none"}}>
                                            <div className="d-flex align-items-center">
                                            <a href={`tel:${orderData.mainFields["Company Phone Number"]}`}><FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon></a>
                                                <div className="ms-2 text-start">
                                                    <p className="lead" style={{marginTop:"10px",marginBottom:"0px",marginLeft:"10px",marginRight:"10px"}}>{orderData.mainFields["Company Phone Number"]}</p>
                                                    <p style={{marginTop:"0px",marginBottom:"10px",marginLeft:"10px",marginRight:"10px"}}>Work Number</p>
                                                </div>
                                                <hr/>
                                            </div>
                                        </li>
                                    </ul>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <hr />
                                </Row>
                                {(personalLinks.length > 0 || workLinks.length > 0) && <Row>
                                    <div className="text-center"><h3>Social Media</h3></div>
                                        <div className="d-flex justify-content-around">
                                            {workLinks.length>0 && <SmLinkSection links={workLinks} type="Work"></SmLinkSection>}
                                            {personalLinks.length>0 && <SmLinkSection links={personalLinks} type="Personal"></SmLinkSection>}
                                    </div>

                                </Row>}
                                <Button className="text-white" style={{borderColor:'#FF52F8',backgroundColor: '#38B6FF'}} onClick={onSavingContact}>Save Contact</Button>
                            </div>
                        </Card.Body>
                    </Card> 
                </Container>
                <img src={artworkBottomRight} className="mt-3" alt="OkiTech" style={{height:'90px', right: 0, position: 'absolute'}}></img>
            </div> }
         
        </>)
}

export default PresentationCard;