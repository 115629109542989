import InputComponent from "../InputComponent"
import { useState, useEffect, useContext } from "react"
import AuthContext from "../../../../context/authentication/auth-context"

const SecondaryFields = (props) => {

    const [secondary, setsecondary] = useState()
    const authCtx = useContext(AuthContext)

    useEffect(() => {
        setsecondary(authCtx.secondaryFields)
    },[authCtx.secondaryFields])

    const onTyping = (input) =>{
        setsecondary(prevState => {
            const newObject = {...prevState}
            newObject[`${input.inputType}`] = input.value
            return newObject
        })
    }
    useEffect(() => {
        props.onChangingSecondaryFields(secondary)
    },[secondary,props])

    return <>
        {props.loadedProfile && secondary !== undefined && <>
        <div className="d-flex justify-content-around">
            <h2>Secondary Fields</h2>
        </div>
        <InputComponent inputType="Company Phone Number" type="text" onTyping={onTyping} value={secondary["Company Phone Number"]}></InputComponent>
        </>}
        </>
}

export default SecondaryFields