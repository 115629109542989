import { Button, Image } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faMinus } from "@fortawesome/free-solid-svg-icons"
import { useContext, useState, useEffect } from "react"
import CartContext from "../../../context/cart/cart-context"
import AuthContext from "../../../context/authentication/auth-context"
import { getFirestore, doc, updateDoc  } from "firebase/firestore/lite";
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner"

const CartItem = (props) => {
    const [quantity, setQuantity] = useState(props.amount) 
    const cartCtx = useContext(CartContext)
    const authCtx = useContext(AuthContext)
    const [waitingUpdate, setWaitingUpdate] = useState(false)

    useEffect(() => {
        setWaitingUpdate(true)
        if (props.fbApp) {   
            const fbDb = getFirestore(props.fbApp)
            const userRef = doc(fbDb,'users',authCtx.userId)
            updateDoc(userRef,{
                cart: cartCtx.items
            }).then((res) => {
                setWaitingUpdate(false)
            })
        }
    },[cartCtx.items])

    const onAddingItem = (e) => {
        e.preventDefault()
        setQuantity(prevState => prevState + 1)
        cartCtx.addItem({
            id: props.id,
            amount: 1,
            price: props.price,
            name: props.name,
        })
    }

    const onRemovingItem = (e) => {
        e.preventDefault()
        setQuantity(prevState => prevState - 1)
        cartCtx.removeItem(props.id)
    }

    const onApplyingNewQuantity = (e) => {
        if (e.target.value !== props.amount || e.target.value === "") {
            props.enableCheckoutButton(false)
        } else {
            props.enableCheckoutButton(true)
        }
        setQuantity(e.target.value)
    }

    const onUpdatingItem = () => {
        for (let i = 1; i <= props.amount; i++) {
            cartCtx.removeItem(props.id)
        }
        cartCtx.addItem({
            id: props.id,
            price: props.price,
            amount: quantity
        })
        props.enableCheckoutButton(true)
    }

    return <div className="d-flex justify-content-between">
        <div className="text-center">
            <h4>{props.name}</h4>
            <Image src={props.imgUrl} style={{width: '175px'}}></Image>
        </div>
        <div className="text-center">
            {/* <input className="col-2 text-center" type="text" value={isNaN(quantity) ? "0" : quantity} onChange={onSettingQuantity}></input> */}
            <input className="col-2 text-center" type="text" value={quantity} onChange={onApplyingNewQuantity}></input>
            <br/>
            <br/>
            <div>
                <Button disabled={quantity === "" || quantity === props.amount} onClick={onUpdatingItem}>Update</Button>   
            </div>

            <br/>
            <div>
                {!waitingUpdate && <Button className="bg-light text-black" onClick={onAddingItem}>
                    <FontAwesomeIcon icon={faPlus} ></FontAwesomeIcon>
                </Button>}
                {waitingUpdate && <LoadingSpinner></LoadingSpinner>}
                {!waitingUpdate && <Button className="bg-light text-black" onClick={onRemovingItem}>
                    <FontAwesomeIcon icon={faMinus} ></FontAwesomeIcon>
                </Button>}
            </div>
        </div>

    </div>
}

export default CartItem