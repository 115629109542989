import { Container } from "react-bootstrap"
import AlertWindow from "../ui/alerts/Alert"
import Header from "../layout/header/Header"

const SubscriptionModified = (props) =>{
    return <>
    <Header loadedProfile={props.loadedProfile}></Header>
    <Container>
        <br/>
        <AlertWindow variant="success" message="Your membership might have been modified, please check the 'My Membership' page to verify"></AlertWindow>
    </Container>
    </>
}

export default SubscriptionModified