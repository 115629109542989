import { useState, useEffect, useContext } from "react"
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import AuthContext from "../../../../context/authentication/auth-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmarkCircle} from '@fortawesome/free-solid-svg-icons'

const PictureUploadEntry = (props) => {
    const [selectedPictureFile, setSelectedPictureFile] = useState()
    const [picturePreview, setPicturePreview] = useState()
    const [linkStyle, setlinkStyle] = useState("col-12")

    const authCtx = useContext(AuthContext)

    useEffect(() => {
        if (!selectedPictureFile && authCtx.pictureUrl === "") {
          setPicturePreview(undefined);
          return;
        }
        if (!selectedPictureFile && authCtx.pictureUrl !== "") {
          setPicturePreview(authCtx.pictureUrl);
          setlinkStyle("col-lg-10 col-8")
          return;
        }
        const objectURL = URL.createObjectURL(selectedPictureFile);
        setPicturePreview(objectURL);
        setlinkStyle("col-lg-10 col-8")
        return () => URL.revokeObjectURL(objectURL);
    }, [selectedPictureFile, authCtx.pictureUrl]);
    
    const onSelectPictureFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
          setSelectedPictureFile(undefined);
          return;
        }
        setlinkStyle("col-lg-10 col-8")
        setSelectedPictureFile(e.target.files[0]);
    };

    const removePicture = () => {
        
        if (authCtx.pictureUrl === "") {
            setlinkStyle("col-12")
        }
        setSelectedPictureFile(undefined)
        setPicturePreview(undefined)
    }

    useEffect(() => {
        props.onUploadingPicture(selectedPictureFile)
    },[selectedPictureFile])
    

    return <>
        <Form.Group className="mb-2" controlId={props.pictureType}>
            <Row>
            {(selectedPictureFile || picturePreview) && (<>
                <Col className="col-lg-2 col-4 d-flex justify-content-around align-items-center">
                    <div className="d-inline-block" style={{width: '80px', height: '80px', overflow: 'hidden', borderRadius: '50%', paddingLeft: "0", paddingRight: "0"}}>
                        <Image rounded  alt="picturePreview" src={picturePreview} style={{width: '80px', height: '80px', objectFit: 'cover'}}></Image>
                    </div>
                    <div className="d-inline-block ps-2">
                        <FontAwesomeIcon color="red" role="button" icon={faXmarkCircle} onClick={removePicture}></FontAwesomeIcon>
                    </div>
                    
                </Col>
            </>)}
                <Col className={linkStyle}>
                    <Form.Label className="mb-2">
                        {`Upload ${props.pictureType}`}
                    </Form.Label>
                    <Form.Control type="file" onChange={onSelectPictureFile} />
                </Col>

            </Row>
        </Form.Group>
    </>
}

export default PictureUploadEntry