import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {faMinus} from '@fortawesome/free-solid-svg-icons'

const AddButton = (props) => {

    const onClicking = () => {
        if (props.type === "Remove") {
            props.onAdding({
                type: props.type,
                widget: props.widget,
                subType: props.subType
            })
        } else if (props.type === "Add") {
            props.onAdding({
                type: props.type,
                widget: props.widget,
                subType: props.subType
            })
        }
    }

    return <Button onClick={onClicking}>
        <div className="d-flex justify-content-around align-items-center">
            {props.type === 'Remove' && <FontAwesomeIcon color="white" icon={faMinus}  className="ps-0"></FontAwesomeIcon>}
            {props.type === 'Add' && <FontAwesomeIcon color="white" icon={faPlus}  className="pe-0"></FontAwesomeIcon>}
            {props.subType && <p className="mb-0 ">{`${props.type} ${props.subType} ${props.widget}`}</p>}
            {!props.subType && <p className="mb-0 ">{`${props.type} ${props.widget}`}</p>}
        </div>
    </Button>
}

export default AddButton