import igLogo from "../../../../assets/images/instagram.png"
import liLogo from "../../../../assets/images/linkedin.png"
import twitterLogo from "../../../../assets/images/x.png"
import facebookLogo from "../../../../assets/images/facebook.png"
import tiktokLogo from "../../../../assets/images/tiktok.png"
import youtubeLogo from "../../../../assets/images/youtube.png"
import wwwLogo from "../../../../assets/images/www.png"
import { Button } from "react-bootstrap"
// import { useState } from "react"

const SmLinkSection = (props) => {

    const content = props.links.map((link,index) => {
        let url = link.url
        if (!url.includes('https://')) {
            url = 'https://' + url
        }
        if (url === "https://") {
            return
        }
        let logo
        let name
        if (link.url.includes("instagram")) {
            logo = igLogo
            name = link.name
        } else if (link.url.includes("linkedin")) {
            logo = liLogo
            name = "Linked In"
        } else if (link.url.includes("www.x.com") || link.url.includes("twitter")) {
            logo=twitterLogo
            name = link.name
        } else if (link.url.includes("facebook")) {
            logo=facebookLogo
            name = link.name
        } else if (link.url.includes("tiktok")) {
            logo=tiktokLogo
            name = link.name
        } else if (link.url.includes("youtube")) {
            logo=youtubeLogo
            name = link.name
        } else {
            logo=wwwLogo
            name=link.name
        }

        return <div key={index}>
                    <Button href={url} target="_blank" className="d-block text-black" style={{width:'150px', borderColor:'#FF52F8', backgroundColor:'#38B6FF'}}>
                        <div className="d-flex justify-content-start align-items-center">
                                <img className="mx-1" src={logo} style={{width:"30px", height: "30px"}} alt={"unknown link"}/>
                            <p className="m-auto">{name}</p>
                        </div>
                    </Button>
                    <br/>
                </div>
    })

    return <>
        <div className="text-center">
            <p>{`${props.type}`}</p>
            <div>
                {content}
            </div>
            <br/>
        </div>
    </>

}

export default SmLinkSection