import Card from "react-bootstrap/Card";
import CartContext from "../../../context/cart/cart-context";
import { useContext, useState} from "react";
import { Button, Container } from "react-bootstrap";
import AlertWindow from "../../ui/alerts/Alert";

const ProductItem = (props) => {

    const [quantity, setQuantity] = useState("0")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setsuccessMessage] = useState("")
    const cartCtx = useContext(CartContext);

    const onSettingQuantity = (e) => {
      
      if(isNaN(e.target.value)) {
        setErrorMessage("non-numerical characters are not allowed")
      } else {
        setQuantity(parseInt(e.target.value))
        setErrorMessage("")
      }
    }

    const onAddingItemToCart = () => {
      cartCtx.addItem({
        id: props.id,
        amount: quantity,
        price: props.price,
        name: props.name,
      })
      setsuccessMessage(`You have added ${quantity} units of this item successfully to your cart, you may keep adding more items or go straight to checkout`)
      setQuantity("0")
    }

    return <>
    <Container>
      <div className="d-flex justify-content-around text-center">
        <Card style={{ width: "18rem" }} className="mt-5 mx-5">
          <Card.Img variant="top" src={props.imgUrl}/>
          <Card.Body>
            <Card.Title>{props.name}</Card.Title>
            <br/>
            <Card.Text>{props.subtitle}</Card.Text>
            <br/>
            <Card.Subtitle>{props.price} AUD / Each</Card.Subtitle>
            <br/>
            <div>
              <input className="col-2 text-center" type="text" value={isNaN(quantity) ? "0" : quantity} onChange={onSettingQuantity}></input>
            </div>
            <br/>
            <div>
              <Button disabled={quantity ==="0" || quantity === "" || quantity === 0 || isNaN(quantity)} onClick={onAddingItemToCart}>Add To Cart</Button>
            </div>
            <br/>
            {errorMessage !== "" &&  <div>
              <AlertWindow variant="danger" message={errorMessage}></AlertWindow>
            </div>}
            {successMessage !== "" &&  <div>
              <AlertWindow variant="success" message={successMessage}></AlertWindow>
            </div>}
          </Card.Body>
        </Card>
      </div>
    </Container>
    </>
}

export default ProductItem