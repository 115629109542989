import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import './ModalScreen.css'

const ModalScreen = (props) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    navigate('/');
  };
  return (
    <Container className="modal">
      <Modal show={props.show && show} onHide={handleClose}>
        <Modal.Header className=" d-block text-center">
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{props.children}</Modal.Body>
      </Modal>
    </Container>
  );
};

export default ModalScreen;
