import { Container, Form } from "react-bootstrap"
import Header from "../../layout/header/Header"
import PictureUploadEntry from "./PictureUploadEntry/PictureUploadEntry"
import SmLinksForm from "./smLinks/smLinksForm"
import Button from "react-bootstrap/Button";
import { useState, useContext, useEffect } from "react";
import { getFirestore,updateDoc,doc, setDoc, getDoc } from "firebase/firestore";
import AuthContext from "../../../context/authentication/auth-context";
import AlertWindow from "../../ui/alerts/Alert";
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";
import AddressFields from "./AddressFields/AddressFields";
import MandatoryFields from "./MandatoryFields/MandatoryFields";
import UrlTable from "./UrlTable.js/UrlTable";
import SecondaryFields from "./SecondaryFields/SecondaryFields";

const ProfileSettings = (props) => {

    const [linkArrays, setlinkArrays] = useState([[],[]])
    const [errorMessage, setErrorMessage] = useState("")
    const [doneUpdating, setdoneUpdating] = useState(false)
    const [cardIsActive, setcardIsActive] = useState(false)
    const [doneActivatingCard, setdoneActivatingCard] = useState(false)
    const [donedeactivating, setdonedeactivating] = useState(false)
    const [updatingProfile, setupdatingProfile] = useState(false)
    const [updatingCard, setupdatingCard] = useState(false)
    const [pictureFile, setpictureFile] = useState()
    const [address, setaddress] = useState({})
    const [mainFields, setMainFields] = useState({})
    const [activateButtonActive, setactivateButtonActive] = useState(false)
    const [updateButtonActive, setupdateButtonActive] = useState(false)
    const [secondaryFields, setsecondaryFields] = useState({})

    const authCtx = useContext(AuthContext);
    // console.log(linkArrays)

    useEffect(() => {
        if (authCtx.isLoggedIn && authCtx.cardActive) {
            setcardIsActive(true)
        } else if (authCtx.isLoggedIn && !authCtx.cardActive) {
            setcardIsActive(false)
        }

        if (Object.keys(authCtx.mainFields).length === 0 || authCtx.pictureUrl === "" || authCtx.status !== "active") {
            setactivateButtonActive(false)
        } else {
            setactivateButtonActive(true)
        }
    },[authCtx.isLoggedIn, authCtx.cardActive])

    useEffect(() => {
        if(mainFields !== null && mainFields !== undefined) {
            // console.log("mainFields are not null or undefined")
            if ((Object.keys(mainFields).includes("First Name") && 
                Object.keys(mainFields).includes("Last Name") && 
                Object.keys(mainFields).includes("Company") && 
                Object.keys(mainFields).includes("Title") && 
                Object.keys(mainFields).includes("Company Email Address") && 
                Object.keys(mainFields).includes("Personal Phone Number")) && 
                (pictureFile !== undefined || (authCtx.pictureUrl !== undefined && authCtx.pictureUrl !== ""))) {
                    // console.log("Setting Update button to active")
                    setupdateButtonActive(true)
            } else {
                // console.log("Setting Update button to inactive")
                setupdateButtonActive(false)
            }
        }
            
    },[pictureFile, mainFields, authCtx.pictureUrl])

    const onUploadingPicture = (pictureFile) => {
        setpictureFile(pictureFile)
    }

    const onUpdatingProfile = (e) => {
        e.preventDefault()
        // if (pictureFile === undefined && authCtx.)
        setupdatingProfile(true)
        const fbDb = getFirestore(props.fbApp);
        const fbStorage = getStorage(props.fbApp);
        const docRef = doc(fbDb, "users", authCtx.userId);
        const picRef = ref(fbStorage, `pictures/${authCtx.userId}`);
        // console.log(authCtx.cardUrl)
        // console.log(linkArrays)
        updateDoc(docRef,{
            personalSmLinkArray: linkArrays[0],
            workSmLinkArray: linkArrays[1],
            address: address,
            mainFields: mainFields,
            secondaryFields: secondaryFields,
           //SecondaryFieldssecsecondaryFields
        }).then(res => {
            if(pictureFile) {
                // console.log("A picture was found")
                uploadBytes(picRef, pictureFile).then((res) => {
                    getDownloadURL(res.ref).then((url) => {
                      updateDoc(docRef, {
                        pictureUrl: url,
                      }).then((res) => {
                        setupdatingProfile(false)
                        setErrorMessage("")
                        setdoneUpdating(true)
                        authCtx.updateMainFields(mainFields)
                        authCtx.updateSecondaryFields(secondaryFields)
                        authCtx.updatePictureUrl(url)
                        //Add logic to modify current card if existing
                        if (authCtx.cardUrl !== "") {
                            const cardsRef = doc(fbDb, "cards", authCtx.cardUrl);
                            updateDoc(cardsRef, {
                                personalSmLinkArray: linkArrays[0],
                                workSmLinkArray: linkArrays[1],
                                address: address,
                                mainFields: mainFields,
                                secondaryFields: secondaryFields,
                            }).then(() => {
                                if (authCtx.cardUrl !== "") {
                                    const cardsRef = doc(fbDb, "cards", authCtx.cardUrl);
                                    // console.log('Personal Links',linkArrays[0])
                                    // console.log('Work Links',linkArrays[1])
                                    // console.log('address',address)
                                    // console.log('mainFields',mainFields)
                                    updateDoc(cardsRef, {
                                        personalSmLinkArray: linkArrays[0],
                                        workSmLinkArray: linkArrays[1],
                                        address: address,
                                        mainFields: mainFields,
                                        secondaryFields: secondaryFields,
                                        pictureUrl: url,
                                        activeCard: authCtx.cardActive,
                                    }).then(() => {
                                        setupdatingProfile(false)
                                        setErrorMessage("")
                                        setdoneUpdating(true)
                                        authCtx.updateMainFields(mainFields)
                                        authCtx.updatePictureUrl(url)
                                    }).catch((err) => {
                                        setErrorMessage(err.message);
                                        setupdatingProfile(false)
                                    })
                                }
                            }).catch((err) => {
                                setErrorMessage(err.message);
                                setupdatingProfile(false)
                            })
                        } else {
                            setupdatingProfile(false)
                            setErrorMessage("")
                            setdoneUpdating(true)
                        }
                    }).catch((err) => {
                          setErrorMessage(err.message);
                        });
                    });
                  });
            } else {
                if (authCtx.cardUrl !== "") {
                    const cardsRef = doc(fbDb, "cards", authCtx.cardUrl);
                    // console.log(authCtx.cardUrl)
                    // console.log(linkArrays)
                    // console.log('Personal Links',linkArrays[0])
                    // console.log('Work Links',linkArrays[1])
                    // console.log('address',address)
                    // console.log('mainFields',mainFields)
                    updateDoc(cardsRef, {
                        personalSmLinkArray: linkArrays[0],
                        workSmLinkArray: linkArrays[1],
                        address: address,
                        mainFields: mainFields,
                        secondaryFields: secondaryFields,
                        pictureUrl: authCtx.pictureUrl,
                        activeCard: authCtx.cardActive,
                    }).then(() => {
                        setupdatingProfile(false)
                        setErrorMessage("")
                        setdoneUpdating(true)
                        authCtx.updateMainFields(mainFields)
                        authCtx.updatePictureUrl(authCtx.pictureUrl)
                    }).catch((err) => {
                        setErrorMessage(err.message);
                        setupdatingProfile(false)
                    })
                } else {
                    setupdatingProfile(false)
                    setErrorMessage("")
                    setdoneUpdating(true)
                }
            }
        }).catch(err => {
            setErrorMessage(err.message)
            setupdatingProfile(false)
        })
    }
    // console.log(mainFields)
    const onActivatingCard = () => {
        //Check if a card URL already exists
        setupdatingCard(true)
        //If context has not yet set the card Url, then this is an activation for sure
        if (authCtx.cardUrl === "") {
            let fileName = String(Date.now());
            fileName = fileName + "-" + authCtx.userId;
            const fbDb = getFirestore(props.fbApp);
            const cardsRef = doc(fbDb, "cards", fileName);
            const usersRef = doc(fbDb, "users", authCtx.userId);
            let subsRef
            //Check if the user has a subscription id
            if (authCtx.subId !== "") {
                subsRef = doc(fbDb, "subscriptions", authCtx.subId);
            } else {
                //If not the process should stop
                // console.log("subId not present in users profile")
                return
            }
            //Retrieve subscription
            getDoc(subsRef).then(res => {
                //Once the subscription has been retrieved, make sure that the number of seats is at least 1 or more
                //if not, return an error and stop the current process
                if (res.data().seats < 1) {
                    setErrorMessage("Your current subscription has reached its limit of digital cards")
                    setupdatingCard(false)
                    setdoneActivatingCard(true)
                    return
                } else {
                    //If there are still seats available, we need to update the subscription document and reduce the number of seats by one
                    updateDoc(subsRef,{
                        seats: res.data().seats - 1
                    }).then(res2 => {
                        //Then, create the card document and upload it to the "Cards" table
                        setDoc(
                            cardsRef,
                            {
                                mainFields: authCtx.mainFields,
                                secondaryFields: authCtx.secondaryFields,
                                address: authCtx.address,
                                personalSmLinkArray: authCtx.personalSmLinkArray,
                                workSmLinkArray: authCtx.workSmLinkArray,
                                pictureUrl: authCtx.pictureUrl,
                                activeCard: true,
                            },
                            { merge: true }
                          ).then((res3) => {
                            //Then, update the users document for the current user with the card url and setting the "cardActive" field to true
                            setDoc(usersRef,{
                                cardUrl:`${fileName}`,
                                cardActive: true
                            },{
                                merge:true
                            }).then(res4 => {
                                //If we get up to this point, it is because the process has been complete successfully
                                setcardIsActive(true)
                                setErrorMessage("")
                                setdoneActivatingCard(true)
                                setupdatingCard(false)
                                //Below is the capture of errors if any of the above API calls failed
                                //NOTE: it is possible that one table was updated and not others, we might need to think of a different way
                            }).catch(err =>{
                                setErrorMessage(err.message)
                                setupdatingCard(false)
                                setdoneActivatingCard(true)
                            })
                          }).catch(err => {
                            setErrorMessage(err.message)
                            setupdatingCard(false)
                            setdoneActivatingCard(true)
                          });
                    }).catch(err => {
                        setErrorMessage(err.message)
                        setupdatingCard(false)
                        setdoneActivatingCard(true)
                    })
                }
            })
             
            //NOW what happens if the card URL already exists in the user profile?
        } else {
            const fbDb = getFirestore(props.fbApp);
            const usersRef = doc(fbDb, "users", authCtx.userId);
            let subsRef
            if (authCtx.subId !== "") {
                subsRef = doc(fbDb,"subscriptions", authCtx.subId)
            } else {
                return
            }
            //flipActiveStatus indicates if this is an activation "true" or a deactivation "false"
            const flipActiveStatus = !cardIsActive
            getDoc(subsRef).then(res=>{
                //First if flipActiveStatus is true (activation), check if the number of available seats is less than 1 so an error can be presented and the logic will end
                if (flipActiveStatus && res.data().seats < 1) {
                    setErrorMessage("Your current subscription has reached its limit of digital cards")
                    setupdatingCard(false)
                    setdoneActivatingCard(true)
                    return
                    //Otherwise, reduce the number of seats by one in the subscription document
                } else if (flipActiveStatus && res.data().seats > 0) {
                    updateDoc(subsRef,{
                        seats: res.data().seats - 1
                    }).then(res2=>{
                    }).catch(err => {
                        setErrorMessage(err.message)
                        setupdatingCard(false)
                        setdoneActivatingCard(true)
                    })
                    //If this is a deactivation, then add one more seat to the subscriptions document
                } else if (!flipActiveStatus) {
                    updateDoc(subsRef,{
                        seats: res.data().seats + 1
                    }).then(res2=>{
                    }).catch(err => {
                        setErrorMessage(err.message)
                        setupdatingCard(false)
                        setdoneActivatingCard(true)
                    })
                }
                //Now update the users doc, with the new status of the card (Active or Not)
                setDoc(usersRef,{
                    cardActive: flipActiveStatus
                },{
                    merge:true
                }).then(res => {
                    setErrorMessage("")
                    setupdatingCard(false)            
                    if (flipActiveStatus) {
                        setdoneActivatingCard(true)
                        setdonedeactivating(false)
                        setcardIsActive(true)
                    } else {
                        setdonedeactivating(true)
                        setdoneActivatingCard(false)
                        setcardIsActive(false)
                    }
                }).catch(err => {
                    setErrorMessage(err.message)
                    setupdatingCard(false)
                })
            }).catch(err => {
                setErrorMessage(err.message)
                setupdatingCard(false)
                setdoneActivatingCard(true)
            })
        }
    }

    const onUpdatingLinks = (linkArrays) => {
        setlinkArrays(linkArrays)
    }

    const onChangingAddress = (addressObject) => {
        setaddress(addressObject)
    }

    const onChangingMainFields = (mainFields) => {
        setMainFields(mainFields)
    }

    const onChangingSecondaryFields = (secondaryFields) => {
        setsecondaryFields(secondaryFields)
    }

    return <>
    
    <Header loadedProfile={props.loadedProfile} ></Header>
    <br/>
    {!props.loadedProfile && <div className="text-center"><h2>Loading Card Settings...</h2><br/><LoadingSpinner></LoadingSpinner></div>}
    {props.loadedProfile && <Container>
    <br/>
    <UrlTable cardActive={cardIsActive}></UrlTable>
    {/* <br/> */}
    {!updatingProfile && !updatingCard && !cardIsActive && <div className="d-flex justify-content-around"><Button disabled={!activateButtonActive} className="m-1" type="button" variant="primary" onClick={onActivatingCard}>
        Activate Digital Business Card
    </Button></div>}
    {!activateButtonActive && authCtx.status === 'active' && <AlertWindow variant="danger" message={<p>In order to activate your digital card, please update your card settings below with at least <span className="fw-bold">all the main fields and the profile picture</span></p>}></AlertWindow>}
    {!activateButtonActive && authCtx.status !== 'active' && <AlertWindow variant="danger" message={<p>You are not a member. In order to activate your digital card, please sign up as a member or request a subscription ID from your manager</p>}></AlertWindow>}
    {!updatingProfile && !updatingCard && cardIsActive && <div className="d-flex justify-content-around"><Button className="m-1" type="button" variant="danger" onClick={onActivatingCard}>
        Deactivate Digital Business Card
    </Button></div>}
    {updatingCard && <LoadingSpinner ></LoadingSpinner>}
    <br/>
    {donedeactivating  && <AlertWindow variant="danger" message="Your card is now inactive"></AlertWindow>}
    {doneActivatingCard && <AlertWindow variant="success" message="Your card is now active"></AlertWindow>}
    <hr/>
    <Form onSubmit={onUpdatingProfile}>
        <div className="text-center"><h2>Profile Picture</h2></div>
        <PictureUploadEntry pictureType="photo" onUploadingPicture={onUploadingPicture}></PictureUploadEntry>
        <hr/>
        <MandatoryFields onChangingMainFields={onChangingMainFields} loadedProfile={props.loadedProfile}></MandatoryFields>
        <hr/>
        <SecondaryFields onChangingSecondaryFields={onChangingSecondaryFields} loadedProfile={props.loadedProfile}></SecondaryFields>
        <hr/>
        <AddressFields onChangingAddress={onChangingAddress}></AddressFields>
        <hr/>
        <SmLinksForm onUpdatingLinks={onUpdatingLinks}></SmLinksForm>
        <hr/>
        <div className="text-center">
            {!updatingProfile && !updatingCard &&<Button disabled={!updateButtonActive} className="m-1" type="submit" variant="success">
                Update Card Settings
            </Button>}
            {updatingProfile && <LoadingSpinner></LoadingSpinner>}
            <br/>
            {!updateButtonActive && authCtx.status==='active' && <AlertWindow variant="danger" message={<p>In order to update your card settings, please fill out at least the following information: <span className="fw-bold">all the main fields and the profile picture</span></p>}></AlertWindow>}
        </div>
    </Form>
    <br/>
    {errorMessage !== "" && <AlertWindow variant="danger" message={errorMessage}></AlertWindow>}
    {doneUpdating  && <AlertWindow variant="success" message="Your card settings have been modified successfully"></AlertWindow>}
    </Container>}
    </>
}

export default ProfileSettings