import Container from "react-bootstrap/esm/Container";
import './HomeScreen.css'
import { useContext, useState } from "react";
import AuthContext from "../../../context/authentication/auth-context";
// import Footer from "../../layout/footer/Footer";
import VerifyEmailAddress from "../../widgets/VerifyEmailAlert";
import Header from "../../layout/header/Header";
import AlertWindow from "../../ui/alerts/Alert";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner";
import IntroCarousel from "./IntroCarousel";

const HomeScreen = (props) => {
  const authCtx = useContext(AuthContext)
  const [toMembership , setToMembership] = useState(false)

  const navigate = useNavigate()

  const onAccessingPlans = () => {
    setToMembership(true)
    setTimeout(()=>{
      navigate('/membership')
    },3000)
  }
  return (
    <>
      <Header loadedProfile={props.loadedProfile}></Header>
      <Container className="mt-3 text-center">
        <h4>Business Cards with QR code and NFC</h4>
        <br/>
        <div>
          {!props.loadedProfile && <LoadingSpinner></LoadingSpinner>}
          {/* {packageOptions} */}
          {authCtx.isLoggedIn && props.loadedProfile && !authCtx.isVerified && <VerifyEmailAddress></VerifyEmailAddress>}
          {props.loadedProfile && !authCtx.isLoggedIn && <AlertWindow variant="primary" message="Please register or sign in"></AlertWindow>}
          {authCtx.status !== "active" && props.loadedProfile && authCtx.isVerified && 
            
            <div className="justify-content-center d-flex">
              <AlertWindow variant="primary" message="Before setting up your digital card, please access the Membership page and sign up for one of our plans">
                <br/>
                {toMembership && <LoadingSpinner></LoadingSpinner>}
                {!toMembership && <Button onClick={onAccessingPlans}>Access Membership Options</Button>}
              </AlertWindow>
            </div>}
          {/* <div>
            <img
              src={cardImg}
              alt="homeimg"
              className="mainImage"
              
            />
            
          </div> */}
          <br/>
          <br/>
          <IntroCarousel></IntroCarousel>
        </div>
      </Container>
      <br/>
      {/* {props.loadedProfile && <Footer></Footer>} */}
    </>
  );
};

export default HomeScreen;
