import Header from "../../layout/header/Header"
import { useContext, useState } from "react"
import CartContext from "../../../context/cart/cart-context"
import { Button, Card, Container } from "react-bootstrap"
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner"
import { getFirestore, doc, setDoc  } from "firebase/firestore/lite";
import NFCDeskTab from "../../../assets/images/desktab.jpeg"
import NFCSticker from "../../../assets/images/tag.png"
import CartItem from "./CartItem"
import AuthContext from "../../../context/authentication/auth-context"
import AddressFields from "../Profile/AddressFields/AddressFields"
import AlertWindow from "../../ui/alerts/Alert"
import { useNavigate } from "react-router-dom"
//import Footer from "../../layout/footer/Footer"

const CartPage = (props) => {
    const [checkoutButtonEnabled, setCheckoutButtonEnabled] = useState(true)
    const [address, setAddress] = useState({})
    const [onGoingToCheckout, setonGoingToCheckout] = useState(false)
    const [finishedUpdatingOrder, setfinishedUpdatingOrder] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const cartCtx = useContext(CartContext)
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    
    const enableCheckoutButton = (boolean) => {
        setCheckoutButtonEnabled(boolean)
    }

    const onCheckout = (e) => {
        setonGoingToCheckout(true)
        setfinishedUpdatingOrder(false)
        e.preventDefault();
        const fbDb = getFirestore(props.fbApp)
        const timestamp = Date.now()
        const ordersRef = doc(fbDb,"orders", timestamp + "-" + authCtx.userId)
        setDoc(ordersRef, {
            items: cartCtx.items,
            orderConfirmed: false,
            address: address,
        }).then(res => {
            setonGoingToCheckout(false)
            setfinishedUpdatingOrder(true)
            setErrorMessage("")
            navigate("/orderpayment/" + timestamp + "-" + authCtx.userId)
        }).catch(err => {
            setonGoingToCheckout(false)
            setfinishedUpdatingOrder(true)
            setErrorMessage(err.message)
        })
    }

    const onChangingAddress = (address) => {
        setAddress(address)
    }

    console.log(cartCtx)
    return <>
    <Header loadedProfile={props.loadedProfile}></Header>
    <Container>
        {!props.loadedProfile && <><br/><h2 className="text-center">Loading Cart...</h2><br/><LoadingSpinner></LoadingSpinner></>}
        {props.loadedProfile && <><br/><h2 className="text-center">Cart</h2></>}
        {props.loadedProfile && cartCtx.items.length === 0 && <AlertWindow variant="danger" message="Your cart is currently empty"></AlertWindow>}
        <br/>
        {cartCtx.items.map((item) => (         
            <>
                <hr/>
                { item.id === 0 && <CartItem fbApp={props.fbApp} key={0} id={0} price={item.price} amount={item.amount} imgUrl={NFCSticker} name="NFC Sticker" enableCheckoutButton={enableCheckoutButton}></CartItem>}
                { item.id === 1 && <CartItem fbApp={props.fbApp} key={1} id={1} price={item.price} amount={item.amount} imgUrl={NFCDeskTab} name="NFC Desk Tab" enableCheckoutButton={enableCheckoutButton}></CartItem>}                
            </>
        ))}
        {cartCtx.items.length> 0 && 
        <>
            <hr/>
            <AlertWindow variant="danger" message="Please make sure your address is correct for proper delivery"></AlertWindow>
            <AddressFields onChangingAddress={onChangingAddress}></AddressFields>
            <div className="text-center">
                <hr/>
                {!onGoingToCheckout && <Button disabled={!checkoutButtonEnabled} variant="success" onClick={onCheckout}>Checkout</Button>}
                {onGoingToCheckout && !finishedUpdatingOrder && <LoadingSpinner></LoadingSpinner>}
                <br/>
                <br/>
                {!onGoingToCheckout && finishedUpdatingOrder && errorMessage !== "" && <AlertWindow variant="danger" message={errorMessage}></AlertWindow>}
            </div>
        </>}
    </Container>
    {/* {props.loadedProfile && <Footer></Footer>} */}
    </>
}

export default CartPage