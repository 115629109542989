import InputComponent from "../InputComponent"
import { useState, useEffect, useContext } from "react"
import AuthContext from "../../../../context/authentication/auth-context"

const MandatoryFields = (props) => {

    const [mandatory, setMandatory] = useState()
    const authCtx = useContext(AuthContext)

    useEffect(() => {
        setMandatory(authCtx.mainFields)
    },[authCtx.mainFields])

    const onTyping = (input) =>{
        setMandatory(prevState => {
            const newObject = {...prevState}
            newObject[`${input.inputType}`] = input.value
            return newObject
        })
    }
    useEffect(() => {
        props.onChangingMainFields(mandatory)
    },[mandatory,props])

    return <>
    {props.loadedProfile && mandatory !== undefined && <>
        <div className="d-flex justify-content-around">
            <h2>Main Fields</h2>
        </div>
        <InputComponent inputType="First Name" type="text" onTyping={onTyping} value={mandatory["First Name"]}></InputComponent>
        <InputComponent inputType="Last Name" type="text" onTyping={onTyping} value={mandatory["Last Name"]}></InputComponent>
        <InputComponent inputType="Company" type="text" onTyping={onTyping} value={mandatory["Company"]}></InputComponent>
        <InputComponent inputType="Title" type="text" onTyping={onTyping} value={mandatory["Title"]}></InputComponent>
        <InputComponent inputType="Company Email Address" type="text" onTyping={onTyping} value={mandatory["Company Email Address"]}></InputComponent>
        <InputComponent inputType="Personal Phone Number" type="text" onTyping={onTyping} value={mandatory["Personal Phone Number"]}></InputComponent>
        </>}

        </>
}

export default MandatoryFields