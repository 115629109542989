import { Dropdown, DropdownButton } from "react-bootstrap"
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faXmarkCircle} from '@fortawesome/free-solid-svg-icons'
import igLogo from "../../../../assets/images/instagram.png";
import liLogo from "../../../../assets/images/linkedin.png"
import twitterLogo from "../../../../assets/images/x.png"
import facebookLogo from "../../../../assets/images/facebook.png"
import tiktokLogo from "../../../../assets/images/tiktok.png"
import youtubeLogo from "../../../../assets/images/youtube.png"
import wwwLogo from "../../../../assets/images/www.png"

const SmLinkEntry = (props) => {
    const [linkSelected, setlinkSelected] = useState("")
    const [linkActive, setlinkActive] = useState(false)
    const [logo, setlogo] = useState("")
    const [url, setUrl] = useState(props.url)
    const [name, setname] = useState(props.name)
    
    const setLogoMethod = (linkSelected) => {
        switch (linkSelected) {
            case "Instagram":
                setlogo(<img className="mx-1" src={igLogo} style={{width:"20px"}} alt={"instagram"}/>)
                break
            case "Linked In":
                setlogo(<img className="mx-1" src={liLogo} style={{width:"20px"}} alt={"LinkedIn"}/>)
                break
            case "X/Twitter":
                setlogo(<img className="mx-1" src={twitterLogo} style={{width:"20px"}} alt={"X/Twitter"}/>)
                break
            case "Facebook":
                setlogo(<img className="mx-1" src={facebookLogo} style={{width:"20px"}} alt={"Facebook"}/>)
                break
            case "TikTok":
                setlogo(<img className="mx-1" src={tiktokLogo} style={{width:"20px"}} alt={"TikTok"}/>)
                break
            case "Youtube":
                setlogo(<img className="mx-1" src={youtubeLogo} style={{width:"20px"}} alt={"Youtube"}/>)
                break
            case "Website":
                setlogo(<img className="mx-1" src={wwwLogo} style={{width:"20px"}} alt={"Website"}/>)
                break
            default:
                setlogo(<img className="mx-1" src={wwwLogo} style={{width:"20px"}} alt={"Website"}/>)
        }
    }

    useEffect(()=> {
        if (props.network !== "") {
            setlinkSelected(props.network)
            setlinkActive(true)
            setLogoMethod(props.network)
        }
    },[props.network])
    
    const onSelectingLink = (e) => {
        setlinkSelected(e)
        setLogoMethod(e)
        setlinkActive(true)
    }

    const onTypingLink = (e) => {
        setUrl(e.target.value)
        props.onTyping({url: e.target.value, subType: props.subType, network: linkSelected, key: props.index, name: name})
    }

    const onTypingName = (e) => {
        setname(e.target.value)
        props.onTyping({url: url, subType: props.subType, network: linkSelected, key: props.index, name: e.target.value})
    }

    const removeLink = () => {
        props.onRemoving({index: props.index, subType:props.subType})
    }

    return <>
        <div className="input-group mb-3 align-items-center">
            <div className="input-group-prepend" >
                <div className="d-flex justify-content-around">
                    <DropdownButton variant="light" id="link" title={logo} onSelect={onSelectingLink}>
                        <Dropdown.Item id="Instagram" eventKey="Instagram"><img className="mx-1" src={igLogo} style={{width:"20px"}} alt={"instagram"}/></Dropdown.Item>
                        <Dropdown.Item id="Linked In" eventKey="Linked In"><img className="mx-1" src={liLogo} style={{width:"20px"}} alt={"linkedin"}/></Dropdown.Item>
                        <Dropdown.Item id="X/Twitter" eventKey="X/Twitter"><img className="mx-1" src={twitterLogo} style={{width:"20px"}} alt={"x/twitter"}/></Dropdown.Item>
                        <Dropdown.Item id="Facebook" eventKey="Facebook"><img className="mx-1" src={facebookLogo} style={{width:"20px"}} alt={"facebook"}/></Dropdown.Item>
                        <Dropdown.Item id="TikTok" eventKey="TikTok"><img className="mx-1" src={tiktokLogo} style={{width:"20px"}} alt={"tiktok"}/></Dropdown.Item>
                        <Dropdown.Item id="Youtube" eventKey="Youtube"><img className="mx-1" src={youtubeLogo} style={{width:"20px"}} alt={"youtube"}/></Dropdown.Item>
                        <Dropdown.Item id="Website" eventKey="Website"><img className="mx-1" src={wwwLogo} style={{width:"20px"}} alt={"website"}/></Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>
            <input className="form-control urlInput" type="text" onChange={onTypingName} placeholder="Type Link Name" disabled={!linkActive} defaultValue={props.name}/>
            <input className="form-control urlInput" type="text" onChange={onTypingLink} placeholder="Paste URL here" disabled={!linkActive} defaultValue={props.url}/>
            <div className="d-inline-block ps-2 ">
                <FontAwesomeIcon color="red" role="button" icon={faXmarkCircle} onClick={removeLink}></FontAwesomeIcon>
            </div>
        </div>
    </>
}

export default SmLinkEntry