import { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import AlertWindow from "../ui/alerts/Alert";
import AuthContext from "../../context/authentication/auth-context";
import { getFirestore, doc, updateDoc, setDoc } from "firebase/firestore/lite";
import Header from "../layout/header/Header";
import LoadingSpinner from "../ui/loadingSpinner/LoadingSpinner";

const Success = (props) => {

    // const [sessionId, setSessionId] = useState()
    const [success, setsuccess] = useState(false)
    const [subscriptionData, setsubscriptionData] = useState({})
    const [errorMessage, setErrorMessage] = useState()
    const [answerReceived, setanswerReceived] = useState(false)

    const authCtx = useContext(AuthContext);
    const fbdB = getFirestore(props.fbApp);

    const query = new URLSearchParams(window.location.search);
    useEffect(() => {
        const sessionId = query.get('session_id')
        if (sessionId && authCtx.userId !== "") {
            //send request to API to retrieve checkout session
            //Temporarily changing the API endpoint to be my local machine
            fetch(process.env.REACT_APP_SUBS_DATA,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sessionId: sessionId,
                }),
            }).then(res=>{
                if (res.ok){
                    setErrorMessage("")
                    res.json().then(res2 => {
                        if (Object.keys(res2).length > 0) {
                            const usersRef = doc(fbdB, "users", authCtx.userId);
                            const subRef = doc(fbdB,"subscriptions",res2.subId);
                            const timestamp = new Date().getTime();
                            const mailRef = doc(fbdB, "mail", String(timestamp));
                            updateDoc(usersRef,{
                                memberType: res2.term.charAt(0).toUpperCase() + res2.term.slice(1),
                                cusId : res2.cusId,
                                status : res2.status,
                                startDate : res2.startDate,
                                endDate: res2.endDate,
                                subId: res2.subId,
                                isMembershipOwner: true,
                                membershipOwner: authCtx.email,
                            }).then(res => {
                                setDoc(subRef,{
                                    memberType: res2.term.charAt(0).toUpperCase() + res2.term.slice(1),
                                    cusId : res2.cusId,
                                    status : res2.status,
                                    startDate : res2.startDate,
                                    endDate: res2.endDate,
                                    seats: res2.seats,
                                    maxSeats: res2.seats,
                                    membershipOwner: authCtx.email,  
                                }).then(res3 => {
                                    console.log('sending email to customer')
                                    setDoc(mailRef,{
                                        to: [authCtx.email],
                                        message: {
                                            subject: 'You have successfully subscribed to an OkiTech Enterprise Membership',
                                            text: `Subscription ID: ${res2.subId}\nMax Number of Seats: ${res2.seats}\n${res2.term.charAt(0).toUpperCase() + res2.term.slice(1)}ly Membership\nIf the number of seats acquired is more than one, you may share the Subscription ID with your colleagues so they can enroll\nENJOY!`
                                        }
                                    }).then(res => {
                                        console.log('sending email to admin')
                                        const mailRef_new = doc(fbdB, "mail", String(timestamp + 1));
                                        setDoc(mailRef_new,{
                                            to: 'okitechorders@gmail.com',
                                            message: {
                                                subject: `${authCtx.email} has successfully subscribed to an OkiTech Enterprise Membership`,
                                                text: `Subscription ID: ${res2.subId}\nMax Number of Seats: ${res2.seats}\n${res2.term.charAt(0).toUpperCase() + res2.term.slice(1)}ly Membership`
                                            }
                                        }).then(res=>{
                                            authCtx.updateMembershipOwner(true,authCtx.email)
                                            authCtx.updateSubscription(res2.startDate, res2.endDate,res2.status,res2.subId,res2.cusId,res2.term.charAt(0).toUpperCase() + res2.term.slice(1))
                                            setErrorMessage("")
                                            setsuccess(true)
                                            setsubscriptionData(res2)
                                            setanswerReceived(true)
                                        }).catch(err=>{
                                            setErrorMessage("Successfully subscribed but notifying emails were not sent")
                                            setanswerReceived(true)
                                        })
                                    }).catch(err => {
                                        setErrorMessage("Successfully subscribed but notifying emails were not sent")
                                        setanswerReceived(true)
                                    })

                                }).catch(err => {
                                    setErrorMessage(err.message + `. Unable to update membership (Subscriptions Table). Please contact site's admin and provide them with the following id: ${res.subId}`)
                                    setanswerReceived(true)
                                })                            
                            }).catch(error => {
                                setErrorMessage(error.message + `. Unable to update membership (Users Table). Please contact site's admin and provide them with the following id: ${res.subId}`)
                                setanswerReceived(true)
                            })
                        }
                    })
                } else {
                    throw new Error ('Something went wrong')
                }
            }).catch(error => {
                setErrorMessage(error.message)
                setanswerReceived(true)
            })   
        } 
    },[authCtx.isLoggedIn])

    return <>
        <Header loadedProfile={props.loadedProfile}></Header>
        <Container className="text-center">
            <br/>
            {!answerReceived && <LoadingSpinner></LoadingSpinner>}
            {(errorMessage !== undefined && errorMessage !== "") && <AlertWindow variant='danger' message={errorMessage + ". Please contact the site's administrator and provide him with the above URL"}></AlertWindow>}
            {answerReceived && success && <AlertWindow variant="success" message={`You have successfully signed up for a ${subscriptionData.term}ly membership plan for ${subscriptionData.seats} users`}></AlertWindow>}
        </Container></>
    
}

export default Success