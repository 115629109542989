import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useRef, useState } from "react";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import AlertWindow from "../../ui/alerts/Alert";
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner";

const PasswordChangeForm = (props) => {

    const [changePasswordMsg, setchangePasswordMsg] = useState("");
    const [updatingPassword, setupdatingPassword] = useState(false);
    const enteredOldPassword = useRef();
    const enteredNewPassword1 = useRef();
    const enteredNewPassword2 = useRef();

    const fbAuth = getAuth(props.fbApp);
    const currentUser = fbAuth.currentUser;

    const onUpdatingPassword = () => {
        if (
        enteredOldPassword.current.value === "" ||
        enteredNewPassword1.current.value === "" ||
        enteredNewPassword2.current.value === ""
        ) {
        setchangePasswordMsg("You missed one of the above fields");
        } else if (
        enteredNewPassword1.current.value !== enteredNewPassword2.current.value
        ) {
        setchangePasswordMsg(
            "The new password and its confirmation password do not match"
        );
        } else {
        setupdatingPassword(true);
        const userCredentials = EmailAuthProvider.credential(
            currentUser.email,
            enteredOldPassword.current.value
        );
        reauthenticateWithCredential(currentUser, userCredentials)
            .then((res) => {
            updatePassword(currentUser, enteredNewPassword1.current.value)
                .then(() => {
                setchangePasswordMsg("Success");
                setupdatingPassword(false);
                })
                .catch((error) => {
                setchangePasswordMsg(error.message);
                setupdatingPassword(false);
                });
            })
            .catch((error) => {
            setchangePasswordMsg(error.message);
            setupdatingPassword(false);
            });
        }
    };
    
    return <>
        <h2 className="text-center">Change Password</h2>
      <Form>
        <Form.Group
          className="mb-2 font-weight-bold"
          controlId="formPlaintextEmail"
        >
          <Form.Label className="mb-2">Old Password</Form.Label>
          <Form.Control type="password" ref={enteredOldPassword} />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formPlaintextPassword">
          <Form.Label className="mb-2">New Password</Form.Label>
          <Form.Control type="password" ref={enteredNewPassword1} />
        </Form.Group>
        <Form.Group className="mb-2" controlId="formPlaintextPassword2">
          <Form.Label className="mb-2">Confirm New Password</Form.Label>
          <Form.Control type="password" ref={enteredNewPassword2} />
        </Form.Group>

        <br />
        <div className="d-flex justify-content-center">
          {updatingPassword ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <Button
              className="m-1"
              type="button"
              variant="primary"
              onClick={onUpdatingPassword}
            >
              Change Password
            </Button>
          )}
        </div>
        <br />
        {changePasswordMsg === "Success" && (
          <AlertWindow
            variant="success"
            message="Password has been changed successfully"
          ></AlertWindow>
        )}
        {changePasswordMsg !== "Success" && changePasswordMsg !== "" && (
          <AlertWindow
            variant="danger"
            message={changePasswordMsg}
          ></AlertWindow>
        )}
      </Form>
    </>
}

export default PasswordChangeForm