import React, { useMemo, useState } from "react";

const AuthContext = React.createContext({
  token: "",
  email: "",
  userId: "",
  isVerified: false,
  isLoggedIn: false,
  pictureUrl: "",
  memberType: "",
  startDate: "",
  endDate: "",
  subId: "",
  cusId: "",
  status: "",
  cancelAtPeriodEnd: "",
  personalSmLinkArray: [],
  workSmLinkArray: [],
  address: {},
  updateAddress: (address) => {},
  mainFields: {},
  secondaryFields: {},
  cardUrl: "",
  updateCardUrl: (url) => {},
  cardActive: false,
  isMembershipOwner: false,
  membershipOwner: "",
  enabledByAdmin: true,
  updateEnabledByAdmin: (bool) => {},
  updateMembershipOwner: (isMembershipOwner, membershipOwner) =>{},
  updateCardActive: (active) => {},
  updateMainFields: (mainFields) => {},
  updateSecondaryFields: (secondaryFields) => {},
  updateLinksArray: (personalSmLinkArray, workSmLinkArray) => {},
  updateSubscription: (startDate, endDate,status,subId,cusId, memberType, cancelAtPeriodEnd) => {},
  orders: [],
  updateOrders: (orders) => {},
  updatePictureUrl: (url) => {},
  updateProfile: (title, phoneNumber, firstName, lastName, companyName, memberType,subId) => {},
  login: (token, email, userId, isVerified) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [pictureUrl, setpictureUrl] = useState("");
  const [memberType, setMemberType] = useState("")
  const [endDate, setendDate] = useState("")
  const [startDate, setstartDate] = useState("")
  const [cusId, setcusId] = useState("")
  const [subId, setsubId] = useState("")
  const [status, setstatus] = useState("")
  const [address,setAddress] = useState({})
  const [cancelAtPeriodEnd, setcancelAtPeriodEnd] = useState("")
  const [isVerified, setisVerified] = useState(false)
  const [personalSmLinkArray, setpersonalSmLinkArray] = useState([])
  const [workSmLinkArray, setworkSmLinkArray] = useState([])
  const [mainFields, setMainFields] = useState({})
  const [cardUrl, setcardUrl] = useState({})
  const [cardActive, setcardActive] = useState(false)
  const [isMembershipOwner, setisMembershipOwner] = useState(false)
  const [membershipOwner, setmembershipOwner] = useState("")
  const [secondaryFields, setsecondaryFields] = useState({})
  const [orders, setOrders] = useState([])
  const [enabledByAdmin, setEnabledByAdmin] = useState(true)

  const userIsLoggedIn = !!token;

  const updateEnabledByAdmin = (bool) => {
    setEnabledByAdmin(bool)
  }

  const updateOrders = (orders) => {
    setOrders(orders);
  }

  const updateSecondaryFields = (secondaryFields) => {
    setsecondaryFields(secondaryFields)
  }

  const updateMembershipOwner = (isMembershipOwner, membershipOwner) => {
    setisMembershipOwner(isMembershipOwner)
    setmembershipOwner(membershipOwner)
  }

  const updateCardActive = (active) => {
    setcardActive(active)
  }

  const updateCardUrl = (url) => {
    setcardUrl(url)
  }

  const updateMainFields = (mainFields) => {
    setMainFields(mainFields)
  }

  const updateAddress = (address) => {
    setAddress(address)
  }

  const updateLinksArray = (personalSmLinkArray, workSmLinkArray) => {
    setpersonalSmLinkArray(personalSmLinkArray)
    setworkSmLinkArray(workSmLinkArray)
  }

  const loginHandler = (token, email, userId, isVerified) => {
    setToken(token);
    setEmail(email);
    setUserId(userId);
    setisVerified(isVerified);
  };
  const logoutHandler = () => {
    setToken(null);
    setUserId(null);
    setEmail(null);
    setMemberType("")
    setendDate("")
    setstartDate("")
    setcusId("")
    setsubId("")
    setstatus("")
    setAddress({})
    setcancelAtPeriodEnd("")
    setisVerified(false)
    setpersonalSmLinkArray([])
    setworkSmLinkArray([])
    setMainFields({})
    setcardUrl({})
    setcardActive(false)
    setisMembershipOwner(false)
    setmembershipOwner("")
    setsecondaryFields({});
    setOrders([]);
  };

  const updatePictureUrl = (url) => {
    setpictureUrl(url);
  };


  const updateSubscription = (startDate, endDate, status, subId, cusId,memberType, cancelAtPeriodEnd)=> {
    setstartDate(startDate)
    setendDate(endDate)
    setstatus(status)
    setsubId(subId)
    setcusId(cusId)
    setMemberType(memberType)
    setcancelAtPeriodEnd(cancelAtPeriodEnd)
  }

  const contextValue = useMemo(() => {
    return {
      token: token,
      email: email,
      userId: userId,
      isVerified: isVerified,
      isLoggedIn: userIsLoggedIn,
      login: loginHandler,
      logout: logoutHandler,
      memberType: memberType,
      startDate: startDate,
      endDate: endDate,
      subId: subId,
      cusId: cusId,
      status: status,
      cancelAtPeriodEnd: cancelAtPeriodEnd,
      enabledByAdmin: enabledByAdmin,
      updateEnabledByAdmin: updateEnabledByAdmin,
      orders: orders,
      updateOrders: updateOrders,
      personalSmLinkArray: personalSmLinkArray,
      isMembershipOwner: isMembershipOwner,
      membershipOwner: membershipOwner,
      setisMembershipOwner: setisMembershipOwner,
      updateMembershipOwner: updateMembershipOwner,
      cardActive: cardActive,
      updateCardActive: updateCardActive,
      cardUrl: cardUrl,
      updateCardUrl: updateCardUrl,
      pictureUrl: pictureUrl,
      updatePictureUrl: updatePictureUrl,
      mainFields: mainFields,
      updateMainFields: updateMainFields,
      secondaryFields: secondaryFields,
      updateSecondaryFields: updateSecondaryFields,
      workSmLinkArray: workSmLinkArray,
      updateLinksArray: updateLinksArray,
      address: address,
      updateAddress: updateAddress,
      updateSubscription: updateSubscription,
    };
  }, [
    token,
    email,
    userId,
    userIsLoggedIn,
    memberType,
    cusId,
    startDate,
    endDate,
    status,
    subId,
    cancelAtPeriodEnd,
    isVerified,
    personalSmLinkArray,
    workSmLinkArray,
    address,
    mainFields,
    cardUrl,
    isMembershipOwner,
    membershipOwner,
    cardActive,
    secondaryFields,
    pictureUrl,
    orders,
    enabledByAdmin,
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
