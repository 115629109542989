import { useState, useEffect,useContext } from "react"
import AuthContext from "../../../../context/authentication/auth-context"
import InputComponent from "../InputComponent"


const AddressFields = (props) => {
    const [address, setAddress] = useState({})
    const authCtx = useContext(AuthContext)

    useEffect(() => {
        setAddress(authCtx.address)
    },[authCtx.address])

    const onTyping = (input) =>{
        setAddress(prevState => {
            const newObject = {...prevState}
            newObject[`${input.inputType}`] = input.value
            return newObject
        })
    } 
    
    useEffect(() => {
        props.onChangingAddress(address)
    },[address, props])

    return <>
        <div className="d-flex justify-content-around">
            <h2>Address</h2>
        </div>
        <InputComponent inputType="Street #" type="text" onTyping={onTyping} value={address['Street #']}></InputComponent>
        <InputComponent inputType="City/Town" type="text" onTyping={onTyping} value={address['City/Town']}></InputComponent>
        <InputComponent inputType="Province" type="text" onTyping={onTyping} value={address['Province']}></InputComponent>
        <InputComponent inputType="Postal Code" type="text" onTyping={onTyping} value={address['Postal Code']}></InputComponent>
    </>
}

export default AddressFields