import { Route, Routes } from "react-router-dom";
import SignInForm from "./components/screens/SignInForm";
import HomeScreen from "./components/screens/HomeScreen/HomeScreen";
import RegisterForm from "./components/screens/RegisterForm";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import AuthContext from "./context/authentication/auth-context";
import CartContext from "./context/cart/cart-context";
import SignOutForm from "./components/screens/SignOutForm";
import { getDoc, doc, getFirestore, updateDoc } from "firebase/firestore";
import QrCodePresenter from "./components/screens/QrCodePresenter";
import PresentationCard from "./components/screens/PresentationCard/PresentationCard";
import Payment from "./components/screens/Payment";
import Success from "./components/screens/Success";
import Membership from "./components/screens/Membership/Membership";
import SubscriptionModified from "./components/screens/SubscriptionModified";
import ForgotPassword from "./components/screens/ForgotPassword";
import Products from "./components/screens/Products/Products";
import ProfileSettings from "./components/screens/Profile/ProfileSettings";
import CartPage from "./components/screens/Cart/CartPage";
import OrderPayment from "./components/screens/Cart/OrderPayment";
import './App.css'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_KEY,
  authDomain: process.env.REACT_APP_FB_DOMAIN,
  projectId: process.env.REACT_APP_FB_PID,
  storageBucket: process.env.REACT_APP_FB_STORAGE,
  messagingSenderId: process.env.REACT_APP_FB_MSID,
  appId: process.env.REACT_APP_FB_APPID,
  measurementId: process.env.REACT_APP_FB_MEASUREID,
};

function App() {
  // Initialize Firebase
  const fbApp = initializeApp(firebaseConfig);
  const fbAuth = getAuth(fbApp);
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);
  const fbDb = getFirestore(fbApp);

  const [loadingProfile, setloadingProfile] = useState(true);
  const [loadedProfile, setloadedProfile] = useState(false)

  useEffect(()=>{
    onAuthStateChanged(fbAuth, (user) => {
      if (user) {
        const docRef = doc(fbDb, "users", user.uid);
        getDoc(docRef).then((res) => {
          authCtx.login(user.accessToken, user.email, user.uid, user.emailVerified);
          updateDoc(docRef, {isVerified: user.emailVerified})
          if (res.data() !== undefined) {
            cartCtx.setCart(res.data().cart)
            authCtx.updateEnabledByAdmin(res.data().enabledByAdmin)
            authCtx.updateMembershipOwner(res.data().isMembershipOwner, res.data().membershipOwner)
            authCtx.updateCardUrl(res.data().cardUrl)
            authCtx.updateCardActive(res.data().cardActive)
            authCtx.updateAddress(res.data().address);
            authCtx.updateMainFields(res.data().mainFields);
            authCtx.updateSecondaryFields(res.data().secondaryFields)
            authCtx.updateLinksArray(res.data().personalSmLinkArray, res.data().workSmLinkArray)
            authCtx.updatePictureUrl(res.data().pictureUrl);
            authCtx.updateOrders(res.data().orders);
            authCtx.updateSubscription(res.data().startDate,res.data().endDate,res.data().status,res.data().subId,res.data().cusId,res.data().memberType, res.data().cancelAtPeriodEnd);
            //If subscription isn't active and card is active, we need to inactivate the card
            if (res.data().status !== "active" && res.data().cardActive === true) {
              updateDoc(docRef, {cardActive: false})
              authCtx.updateCardActive(false)
            }
            setloadingProfile(false);
            setloadedProfile(true)
          }
        });
      } else {
        setloadingProfile(false);
        setloadedProfile(true)
      }
    });
  },[])

  return (
    <div className="App m-0">
      <main style={{minHeight: '100vh'}}>
        <Routes>
          <Route path="/login" element={<SignInForm fbApp={fbApp} loadedProfile={loadedProfile}/>} />
          <Route path="/register" element={<RegisterForm fbApp={fbApp} loadedProfile={loadedProfile}/>} />
          <Route path="/signout" element={<SignOutForm fbApp={fbApp} loadedProfile={loadedProfile}/>} />
          <Route path="/forgotpassword" element={<ForgotPassword fbAuth={fbAuth} loadedProfile={loadedProfile}></ForgotPassword>} />
          <Route path="/products" element={<Products loadedProfile={loadedProfile}></Products>} />
          <Route
            path="/qr/:orderNumber"
            element={<QrCodePresenter fbApp={fbApp} loadedProfile={loadedProfile}></QrCodePresenter>}
          />
          <Route
            path="/card/:orderNumber"
            element={<PresentationCard fbApp={fbApp}></PresentationCard>}
          />
          <Route
            path="/payment/:term"
            element={<Payment fbApp={fbApp} loadedProfile={loadedProfile}></Payment>}
          />
          <Route
            path="/success"
            element={<Success fbApp={fbApp} loadedProfile={loadedProfile}></Success>}
          />
          <Route
            path="/membership"
            element={<Membership fbApp={fbApp} loadingProfile={loadingProfile} loadedProfile={loadedProfile}></Membership>}
          />
          <Route
            path="/subscriptionmodified"
            element={<SubscriptionModified loadedProfile={loadedProfile}></SubscriptionModified>}
          />
          <Route path="/cardsettings" element={<ProfileSettings loadedProfile={loadedProfile} fbApp={fbApp}/>}/>
          <Route path="/cart" element={<CartPage loadedProfile={loadedProfile} fbApp={fbApp}/>}/>
          <Route path="/orderpayment/:orderNumber" element={<OrderPayment loadedProfile={loadedProfile} fbApp={fbApp}/>}/>
          <Route path="/" element={<HomeScreen loadedProfile={loadedProfile} fbApp={fbApp}/>} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
