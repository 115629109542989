import { useContext } from "react"
import AuthContext from "../../../../context/authentication/auth-context"
import { Container, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faQrcode} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

const UrlTable = (props) => {

    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const [cardIsActive, setcardIsActive] = useState(false)

    useEffect(()=>{
        setcardIsActive(props.cardActive)
    },[props.cardActive])

    const onDisplayingQr = () => {
        navigate(`/qr/${authCtx.cardUrl}`)
    }
    return <Container>
        {authCtx.isLoggedIn && authCtx.cardUrl !== "" &&  (<>
        <div className="text-center">
            <h2>Digital Business Card Information</h2>
        </div>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                <th>Card</th>
                <th>QR and URL</th>
                <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td><a href={`https://okitech.com.au/card/${authCtx.cardUrl}`}>See Card</a></td>
                <td><FontAwesomeIcon className="float-center" icon={faQrcode} type="button" onClick={onDisplayingQr}></FontAwesomeIcon></td>
                <td>{cardIsActive ? <p className="text-success fw-bold">Active</p> : <p className="text-danger fw-bold">Inactive</p>}</td>
                </tr>
            </tbody>
        </Table>
        </>)}
        </Container>
}

export default UrlTable