import ModalScreen from "../ui/modal/ModalScreen"
import { useRef, useState } from "react"
import { Form, Container, Button} from "react-bootstrap"
import { sendPasswordResetEmail } from "firebase/auth"
import AlertWindow from "../ui/alerts/Alert"
import Header from "../layout/header/Header"

const ForgotPassword = (props) => {

    const [errorMessage, seterrorMessage] = useState("")
    const onResettingPassword = (e) => {
        e.preventDefault();
        try {
            sendPasswordResetEmail(props.fbAuth, enteredEmail.current.value,{
                url: "https://okitech.com.au/login"
            }).then((res) => {
                seterrorMessage("Success")
            }).catch((err) => {
                seterrorMessage(err.message);
            })
        } catch (err) {
            seterrorMessage(err.message)
        }
    }

    const enteredEmail = useRef()
    return <>
    <Header loadedProfile={props.loadedProfile}></Header>
    <ModalScreen show={true} title="Forgot Password">
        <Form onSubmit={onResettingPassword}>
            <Form.Group className="mb-0" controlId="formPlaintextEmail">
            <Form.Label className="mb-0">Email</Form.Label>
            <Container>
                <Form.Control type="email" ref={enteredEmail} />
            </Container>
            </Form.Group>
            <br/>
            <Button className="m-1" type="submit" variant="primary">
                Submit
            </Button>
        </Form>
        {errorMessage === 'Success' && errorMessage !== "" && <AlertWindow variant="success" message="An email with a link to reset your password has been emailed"></AlertWindow>}
        {errorMessage !== 'Success' && errorMessage !== "" && <AlertWindow variant="danger" message={errorMessage}></AlertWindow>}
    </ModalScreen>
    </>
}
export default ForgotPassword