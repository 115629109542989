import ModalScreen from "../../ui/modal/ModalScreen"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Button, Form } from "react-bootstrap"
import { getFirestore, getDoc, doc, updateDoc, setDoc } from "firebase/firestore"
import { useParams } from "react-router-dom"
import { useState, useEffect, useContext } from "react"
import LoadingSpinner from "../../ui/loadingSpinner/LoadingSpinner"
import AlertWindow from "../../ui/alerts/Alert"
import AuthContext from "../../../context/authentication/auth-context"
import { useNavigate } from "react-router-dom"
import CartContext from "../../../context/cart/cart-context"

const OrderPayment = (props) => {

    const params = useParams()
    const [retrievedDetails, setretrievedDetails] = useState(false)
    const [orderItems, setorderItems] = useState([])
    const [errorMessage, seterrorMessage] = useState("")
    const [submittingOrder, setsubmittingOrder] = useState(false)
    const [paymentError, setpaymentError] = useState("")
    const [payButton, setPayButton] = useState(false)
    const [address, setAddress] = useState({})

    const authCtx = useContext(AuthContext)
    const cartCtx = useContext(CartContext)
    const navigate = useNavigate()
    const elements = useElements();
    const stripe = useStripe();

    console.log(address)
    
    useEffect(()=>{
        if(props.loadedProfile) {
            const fbDb = getFirestore(props.fbApp)
            const orderRef = doc(fbDb,"orders",params.orderNumber)
            getDoc(orderRef).then(res => {
                console.log(res.data())
                setAddress(res.data().address)
                setorderItems(res.data().items)
                setretrievedDetails(true)
                seterrorMessage("")
            }).catch(err => {
                seterrorMessage(err.message)
                setretrievedDetails(true)
            })       
        }
    },[props.fbApp, props.loadedProfile, params.orderNumber])

    const submitHandler = async (e) => {
        e.preventDefault()
        setsubmittingOrder(true)
        if (!stripe || !elements) {
            return;
        }
         const {clientSecret} = await fetch(process.env.REACT_APP_PAYMENT_INTENT,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({items: orderItems}),
        }).then(r => {
            return r.json()
        }).catch(err => {
            setsubmittingOrder(false)
            setpaymentError(err.message)
        })

        if (!clientSecret) {
            console.log("No client Secret retrieved")
            return
        }

        const {paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            }
        })

        if (paymentIntent === undefined) {
            console.log("Payment did not go through, please try again")
            return
        } else {
            const fbDb = getFirestore(props.fbApp)
            const orderRef = doc(fbDb,"orders",params.orderNumber)
            const mailContentArray = cartCtx.items.map(item => (`${item.name} x ${item.amount}`))
            const mailContent = mailContentArray.join("\n")
            updateDoc(orderRef, {
                orderConfirmed: true
            }).then(() => {
                console.log('sending email to user')
                const timestamp = new Date().getTime();
                const mailRef = doc(fbDb,"mail",String(timestamp))
                setDoc(mailRef,{
                    to:[authCtx.email],
                    message: {
                        subject: 'Okitech Order has been placed!',
                        text: `Order Summary:\n${mailContent}\nTotal: ${cartCtx.totalAmount}`
                    }
                }).then(() => {
                    const timestamp_new = timestamp + 1
                    const mailRef_new = doc(fbDb,"mail",String(timestamp_new))
                    console.log('sending email to company')
                    setDoc(mailRef_new,{
                        to: 'okitechorders@gmail.com',
                        message: {
                            subject: 'New order has been placed!',
                            text: `Order Summary:\n${mailContent}\nTotal: ${cartCtx.totalAmount}\n\nShipping Address:\n\nStreet:${address['Street #']}\nCity:${address['City/Town']}\nProvince:${address['Province']}\nPostal Code:${address['Postal Code']}`
                        }
                    }).then(res => {
                        console.log('updating user db')
                        const userRef = doc(fbDb,"users",authCtx.userId)
                        const currentOrders = [...authCtx.orders]
                        currentOrders.push({
                            orderNumber: params.orderNumber,
                            paymentId: paymentIntent.id,
                            total: cartCtx.totalAmount,
                            items: cartCtx.items,
                        })
                        console.log('updating user DB')
                        updateDoc(userRef, {
                            orders: currentOrders,
                            cart: [],
                        }).then(res => {
                            console.log('finalizing order and clearing cart')
                            cartCtx.clearCart();
                            setsubmittingOrder(false)
                            setpaymentError("success")
                            setTimeout(() => {
                                navigate('/')
                            },3000)
                        }).catch(err =>{
                            setsubmittingOrder(false)
                            seterrorMessage(`Order was paid successfully and orders database was updated, but users database was not updated. Please provide the administrator with the following code: ${paymentIntent}. ERROR: ${err.message}`)
                        })
                    }).catch(err => {
                        setsubmittingOrder(false)
                        seterrorMessage(`Order was placed successfully but we may have not received the order. Please email okitechorders@gmail.com with this order number: ${params.orderNumber}`)
                    })  
                }).catch(err => {
                    setsubmittingOrder(false)
                    seterrorMessage(`Order was placed successfully but we could not send you a confirmation email`)
                })
            }).catch(err => {
                setsubmittingOrder(false)
                seterrorMessage(`Order was paid successfully, but something went wrong when updating the orders and users database, please provide the administrator with the following code: ${paymentIntent}. ERROR: ${err.message}`)
            })       

        }
    }

    const onTypingCard = (e) => {
        setPayButton(e.complete)
    }

    return <ModalScreen show={true} title="Order Checkout">
        <h5>Total Summary</h5>
        <br/>
        {!retrievedDetails && <LoadingSpinner></LoadingSpinner>}
        {retrievedDetails && errorMessage === "" && orderItems.map(item => {
            if (item.id === 0) {
                return <p>{`${item.amount} Card Tents @ ${item.price}`}</p>
            }else if (item.id === 1) {
                return <p>{`${item.amount} Card Plastics @ ${item.price}`}</p>
            }
        })}
        {retrievedDetails && errorMessage === "" && <>
            <hr/>
            <span className="fw-bold">{`Total: $${cartCtx.totalAmount} Aud`}</span>
        </>}
        <hr/>
        <Form onSubmit={submitHandler} id="payment-form">
            <label htmlFor="card-element">Credit Card</label>
            <br/>
            <br/>
            <CardElement id="card-element" onChange={onTypingCard}/>
            <br/>
            <hr/>
            {!submittingOrder && <Button disabled={!retrievedDetails || !payButton} type="submit">Pay</Button> }   
            {submittingOrder && <LoadingSpinner></LoadingSpinner> }   
            <br/>
            <br/>
            {!submittingOrder && paymentError !== "success" &&paymentError !== "" && <AlertWindow variant="danger" message={paymentError}></AlertWindow>}   
            {!submittingOrder && paymentError === "success" && <AlertWindow variant="success" message="Thanks for your purchase, go to 'Complementary Products' to review the status of the order"></AlertWindow>}   
        </Form>
    </ModalScreen>
}

export default OrderPayment