import { Table } from "react-bootstrap";
import AuthContext from "../../../../context/authentication/auth-context";
import { useContext } from "react";

const PastOrders = (props) => {

    const authCtx = useContext(AuthContext)

    const content = authCtx.orders.map(order => {
        const products = order.items.map(item => `${item.amount}-${item.name}`).join(' & ')
        return (<tr>
            <td className="small"><small>{`${order.orderNumber.split('-')[0]} / ${order.paymentId}`}</small></td>
            <td className="small"><small>{products}</small></td>
            <td className="small"><small className="fw-bold">{`${order.total}$ AUD`}</small></td>
        </tr>)
    })

    return <>
        {props.loadedProfile && <>
            <h2 className="text-center">Past Orders</h2><br/>
            <Table striped bordered hover size="sm">
            <thead>
                <tr>
                <th><h6>Order ID / Payment ID</h6></th>
                <th><h6>Products</h6></th>
                <th><h6>Total</h6></th>
                </tr>
            </thead>
            <tbody>
                {content}
            </tbody>
            </Table>
        </>}
    </>
}

export default PastOrders