import ModalScreen from "../ui/modal/ModalScreen"
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Button} from "react-bootstrap"
import AlertWindow from "../ui/alerts/Alert"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useContext } from "react"
import AuthContext from "../../context/authentication/auth-context"
import { useNavigate } from "react-router-dom"
import Header from "../layout/header/Header"
// import { getFirestore, doc, updateDoc } from "firebase/firestore"

const Payment = (props) => {
    // const [body, setBody] = useState({})
    const params = useParams()
    const [show, setShow]= useState(true)
    const [amount, setAmount] = useState()
    const [priceId, setpriceId] = useState()
    // const [memberType, setmembertype] = useState("")
    

    const authCtx = useContext(AuthContext)
    const navigate = useNavigate();



    useEffect(() =>{
        if (!params.term.includes("-")) {
            if (params.term === 'Year') {
                setAmount("12.99")
                setpriceId(process.env.REACT_APP_YEARSINGLE_PRICE)
            } else if (params.term === 'Month') {
                setAmount("2.99")
                setpriceId(process.env.REACT_APP_MONTHSINGLE_PRICE)
            }
        } else {
            const term = params.term.split('-')[0]
            const users = params.term.split('-')[1]
            if (term == "Month" && users =="20") {
                setAmount("59.8")
                setpriceId(process.env.REACT_APP_MONTHTWENTY_PRICE)
            } else if (term == "Year" && users =="20") {
                setAmount("233")
                setpriceId(process.env.REACT_APP_YEARTWENTY_PRICE)
            } else if (term == "Month" && users =="50") {
                setAmount("149.5")
                setpriceId(process.env.REACT_APP_MONTHFIFTY_PRICE)  
            } else if (term == "Year" && users =="50") {
                setAmount("584")
                setpriceId(process.env.REACT_APP_YEARFIFTY_PRICE)  
            } else if (term == "Month" && users =="100") {
                setAmount("299")
                setpriceId(process.env.REACT_APP_MONTHHUNDRED_PRICE)  
            } else if (term == "Year" && users =="100") {
                setAmount("1169")
                setpriceId(process.env.REACT_APP_YEARHUNDRED_PRICE)  
            } else if (term == "Month" && users =="10") {
                setAmount("29.9")
                setpriceId(process.env.REACT_APP_MONTHTEN_PRICE)  
            } else if (term == "Year" && users =="10") {
                setAmount("116")
                setpriceId(process.env.REACT_APP_YEARTEN_PRICE)  
            }
        }

    }, [params.term])

    
    const [errorMessage,setErrorMessage] = useState("")

    // const elements = useElements();
    // const stripe = useStripe();

    // const submitHandler = async (e) => {
    //     e.preventDefault();
    //     // console.log("Submitting payment")
    //     console.log(body)
    //     //Sending payment request to server
    //     await fetch('http://localhost:4000/create-checkout-session',{
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             id: priceId
    //         }),
    //     }).then(res => {
    //         console.log(res)
    //     }).catch(error => {
    //         setErrorMessage(error.message)
    //     })

    //     // if (!stripe || !elements) {
    //     //     return;
    //     // }

    //     // const {clientSecret} = await fetch('http://localhost:4000/create-payment-intent',{
    //     //     method: 'POST',
    //     //     headers: {
    //     //         'Content-Type': 'application/json',
    //     //     },
    //     //     body: JSON.stringify(body),
    //     // }).then(r => r.json()).catch(error => {
    //     //     setErrorMessage(error.message)
    //     // })

    //     // setErrorMessage('Success')



    //     // const {paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
    //     //     payment_method: {
    //     //         card: elements.getElement(CardElement),
    //     //     }
    //     // })

    //     // if (paymentIntent === undefined) {
    //     //     setErrorMessage("Payment did not go through, please try again")
    //     //     return
    //     // }
    //     // console.log("Payment Intent should have passed")
    //     // //Set member type to the selected term
    //     // const fbDb = getFirestore(props.fbApp);
    //     // const docRef = doc(fbDb, "users", authCtx.userId);
    //     // updateDoc(docRef, {
    //     //     memberType: params.term,
    //     // }).then(() => {
    //     //     setTimeout(function () {
    //     //         onClosing();
    //     //     }, 2000);
    //     // })

    //     // setErrorMessage((prevState) => {
    //     //     return prevState + `; Payment Intent (${paymentIntent.id}): ${paymentIntent.status}`
    //     // })
    
    // }

    // const onClosing = () => {
    //     setShow(false)
    //     navigate(-1)
    // }

    const onRegistrationHandler = () => {
        navigate('/register')
    }

    const onLoggingInHandler = () => {
        navigate('/login')
    }

    return <>
    <Header loadedProfile={props.loadedProfile}></Header>
    <ModalScreen show={show} title="Payment">
        <h4>{amount}$ AUD</h4>
        {authCtx.isLoggedIn && <>
        <p>{params.term.split('-')[0]}ly Membership</p>
        {/* <Form onSubmit={submitHandler} id="payment-form">
            <label htmlFor="card-element">Card</label>
            <CardElement id="card-element"/>
            <br/>
            
        </Form> */}
        <br/>
        <form action={process.env.REACT_APP_CHECKOUT_SESSION} method="POST">
        {/* <form action="http://localhost:4000/create-checkout-session" method="POST"> */}
            <input type="hidden" name="id" value={priceId} />
            <Button id="checkout-and-portal-button" type="submit">Checkout</Button>
        </form>
        <br/>
        <br/>
        {errorMessage !== "" && !errorMessage.includes("Success") && <AlertWindow variant="danger" message={errorMessage}></AlertWindow>}
        {errorMessage.includes("Success") && <AlertWindow variant="success" message={errorMessage}></AlertWindow>}
        </>}
        {!authCtx.isLoggedIn && <>
            <AlertWindow variant="success" message="Please register or sign into your account"></AlertWindow>
            <Button className="mx-2" onClick={onRegistrationHandler}>Register</Button>
            <Button className="mx-2" onClick={onLoggingInHandler}>Log in</Button>
        </>}
    </ModalScreen>
    </>
}

export default Payment