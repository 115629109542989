import ModalScreen from "../ui/modal/ModalScreen";
import Button from "react-bootstrap/esm/Button";
import { useState, useContext } from "react";
import AlertWindow from "../ui/alerts/Alert";
import AuthContext from "../../context/authentication/auth-context";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Header from "../layout/header/Header";
import LoadingSpinner from "../ui/loadingSpinner/LoadingSpinner";

const SignOutForm = (props) => {
  const [show, setShow] = useState(true);
  const [errorMessage, seterrorMessage] = useState("");
  const authCtx = useContext(AuthContext);
  const fbApp = props.firebaseConn;
  const fbAuth = getAuth(fbApp);
  const navigate = useNavigate()

  const onClosing = () => {
    setShow(false);
    navigate('/')
  };

  const onSigningOut = () => {
    signOut(fbAuth)
      .then((res) => {
        authCtx.logout();
        seterrorMessage("");
        setTimeout(function () {
          onClosing();
        }, 2000);
      })
      .catch((error) => {
        seterrorMessage(error.message.split(":")[1].trim());
      });
  };

  return (<>
    <Header loadedProfile={props.loadedProfile}></Header>
    <ModalScreen show={show} title={"Sign Out"}>
      {authCtx.isLoggedIn && (
        <AlertWindow
          message={"Would you like to log out?"}
          variant={"danger"}
        ></AlertWindow>
      )}
      {!authCtx.isLoggedIn && props.loadedProfile && (
        <AlertWindow
          message={"Thanks! You are already signed out, see you soon"}
          variant={"success"}
        ></AlertWindow>
      )}
      {authCtx.isLoggedIn && errorMessage !== "" && (
        <AlertWindow message={errorMessage} variant={"danger"}></AlertWindow>
      )}
      {!props.loadedProfile && <LoadingSpinner></LoadingSpinner>}
      <Button className="m-1" variant="secondary" onClick={onClosing} disabled={!props.loadedProfile}>
        Exit
      </Button>
      <Button
        className="m-1"
        type="submit"
        variant="danger"
        onClick={onSigningOut}
        disabled={!props.loadedProfile}
      >
        Confirm
      </Button>
    </ModalScreen>
    </>
  );
};

export default SignOutForm;
