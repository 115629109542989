import ModalScreen from "../ui/modal/ModalScreen";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useState, useRef, useContext } from "react";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import AlertWindow from "../ui/alerts/Alert";
import { getFirestore, doc, setDoc } from "firebase/firestore/lite";
import AuthContext from "../../context/authentication/auth-context";
import { useNavigate } from "react-router-dom";
import Header from "../layout/header/Header";

const RegisterForm = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate()

  const enteredEmail = useRef();
  const enteredPassword = useRef();
  const enteredPassword2 = useRef();

  const authCtx = useContext(AuthContext);

  const fbApp = props.fbApp;
  const fbAuth = getAuth(fbApp);
  const fbdB = getFirestore(fbApp);

  const onSigningUp = (e) => {
    e.preventDefault();
    const email = enteredEmail.current.value;
    const password = enteredPassword.current.value;
    const password2 = enteredPassword2.current.value;

    const regularExpression =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;

    if (password !== password2) {
      setErrorMessage("Passwords do not match");
    } else if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters");
    } else if (!regularExpression.test(password)) {
      setErrorMessage(
        "Password should contain at least one uppercase letter, one lowercase letter, one number and one of these special characters: !@#$%^&*()"
      );
    } else {
      createUserWithEmailAndPassword(fbAuth, email, password)
        .then((res) => {
          sendEmailVerification(res.user).then((res2) => {
            const usersRef = doc(fbdB, "users", res.user.uid);
            setDoc(
              usersRef,
              {
                email: res.user.email,
                isVerified: res.user.emailVerified,
                cart: [],
                orders: [],
                cardUrl: "",
                cardActive: false,
                isMembershipOwner: false,
                membershipOwner: "",
                memberType: "",
                personalSmLinkArray: [],
                workSmLinkArray: [],
                address: {},
                mainFields: {},
                secondaryFields: {},
                cusId: "",
                status: "",
                subId: "",
                startDate: "",
                endDate: "",
                cancelAtPeriodEnd: "",
                pictureUrl: "",
                enabledByAdmin: true,
              },
              { merge: true }
            );
          })
          setErrorMessage("success");
          authCtx.login(res.user.accessToken, res.user.email, res.user.uid, res.user.emailVerified);
          setTimeout(function () {
            onClosing();
          }, 2000);
        })
        .catch((error) => {
          const message = error.message;
          const parsedMessage = message.split(":")[1].trim();
          setErrorMessage(parsedMessage);
        });
    }
  };

  const onClosing = () => {
    setShowModal(false);
    navigate('/')
  };

  return (<>
    <Header loadedProfile={props.loadedProfile}></Header>
    <ModalScreen title="Register" show={showModal}>
      <Form onSubmit={onSigningUp}>
        <Form.Group className="mb-0" controlId="formPlaintextEmail">
          <Form.Label className="mb-0">Email</Form.Label>
          <Container>
            <Form.Control type="email" ref={enteredEmail} />
          </Container>
        </Form.Group>
        <Form.Group className="mb-0" controlId="formPlaintextPassword">
          <Form.Label className="mb-0">Password</Form.Label>
          <Container>
            <Form.Control type="password" ref={enteredPassword} />
          </Container>
        </Form.Group>
        <Form.Group className="mb-0" controlId="formPlaintextPassword2">
          <Form.Label className="mb-0">Confirm Password</Form.Label>
          <Container>
            <Form.Control type="password" ref={enteredPassword2} />
          </Container>
        </Form.Group>
        <br />
        <Button className="m-1" variant="secondary" onClick={onClosing}>
          Close
        </Button>
        <Button className="m-1" type="submit" variant="primary">
          Sign Up
        </Button>
      </Form>
      {errorMessage !== "success" && errorMessage !== "" && (
        <>
          <br></br>
          <AlertWindow message={errorMessage} variant="danger"></AlertWindow>
        </>
      )}
      {errorMessage === "success" && (
        <>
          <br></br>
          <AlertWindow
            message={"An email has been sent to you with a link to verify your account. Please verify yoru account before proceeding"}
            variant="success"
          ></AlertWindow>
        </>
      )}
    </ModalScreen>
    </>
  );
};

export default RegisterForm;
