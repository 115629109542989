import { useParams } from "react-router-dom";
import ModalScreen from "../ui/modal/ModalScreen";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Button, Container } from "react-bootstrap";
import Header from "../layout/header/Header";
import LoadingSpinner from "../ui/loadingSpinner/LoadingSpinner";
import AlertWindow from "../ui/alerts/Alert";

const QrCodePresenter = (props) => {
    const params = useParams();
    const fbDb = getFirestore(props.fbApp);
    const docRef = doc(fbDb, "cards", params.orderNumber);
    const [loadingOrder, setloadingOrder] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(()=> {
        getDoc(docRef).then(res=>{
            setloadingOrder(false)
        }).catch(err => {
            setErrorMessage(err.message)
            setloadingOrder(false)
        })
    },[docRef])

    const url = "https://okitech.com.au/card/" + params.orderNumber

    return <>
    <Header loadedProfile={props.loadedProfile}></Header>
    {loadingOrder && <><br/><LoadingSpinner></LoadingSpinner></>}
    {!loadingOrder && errorMessage !== "" && <><Container><br/><AlertWindow variant="danger" message={"Order wasn't found. ERROR: " + errorMessage}></AlertWindow></Container></>}
    {!loadingOrder && <ModalScreen show={true} title={"QR Code and URL"} fbApp={props.fbApp}>
        
        <QRCode value={url}/>
        <br/>
        <br/>
        <p>{`${url}`}</p>
        <Button><a href={url} style={{textDecoration:"none", color:"white"}}>See Presentation Card</a></Button>
    </ModalScreen>}
    </>

}

export default QrCodePresenter;