import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/authentication/auth-context";
import CartProvider from "./context/cart/CartProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

  const {publishableKey} = await fetch(process.env.REACT_APP_GETSPK).then((r) => {
    return r.json()
  })
  const stripePromise = loadStripe(publishableKey)

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <AuthContextProvider>
        <CartProvider>
          <BrowserRouter>
            <Elements stripe={stripePromise}>
              <App />
              </Elements>
            </BrowserRouter>
          </CartProvider>
      </AuthContextProvider>
    </React.StrictMode>
  );

