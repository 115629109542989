import Form from "react-bootstrap/Form";

const InputComponent = (props) => {

    const onChangeTriggered = (e) => {
        props.onTyping({value: e.target.value, inputType: props.inputType})
    }


    return  <Form.Group className="mb-2" controlId={props.inputType}>
    <Form.Label className="mb-2">{props.inputType}</Form.Label>
    <Form.Control
        type={props.type}
        defaultValue={props.value}
        onChange={onChangeTriggered}
    />
  </Form.Group>
}

export default InputComponent